import React from 'react';
import {styles} from '../../Stylesheet.css';
import MaterialTable from 'material-table';
import {Paper, Typography} from '@material-ui/core';

const MessageList = ({
                         title = "Pending",
                         data = [],
                         editable = true,
                         groups = [],
                     }) => {
    const globalStyles = styles();
    return (
        <>
            <Typography className={globalStyles.tableTitle}>{title}</Typography>
            <Paper>
                <MaterialTable
                    title=""
                    data={data}
                    options={{
                        draggable: false,
                        headerStyle: {
                            height: "21px",
                            width: "184px",
                            color: "#1B4297",
                            fontFamily: "Whitney",
                            fontSize: "18px",
                            fontWeight: "600",
                            letterSpacing: 0,
                            lineHeight: "21px",
                        },
                        cellStyle: {
                            maxWidth: "100px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        },
                    }}
                    columns={[
                        {field: "message", title: "Message Text"},
                        {field: "requestedBy", title: "Publisher"},
                        {field: "requestDateTime", title: "Date Sent"},
                    ]}
                />
            </Paper>
        </>
    )
}

export default MessageList;