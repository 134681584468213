/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {styles} from '../../Stylesheet.css';
import moment from 'moment-timezone';
import {
    Typography,
    Grid,
    Paper,
    FormHelperText,
    FormControl,
    FormControlLabel,
    Checkbox,
    TextField,
    LinearProgress,
    Button,
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {Link} from 'react-router-dom';
import ConfirmationDialog from '../common/ConfirmationDialog';
import MessageScheduleModal from './MessageScheduleModal';
import {useHistory} from 'react-router-dom';
import routes from '../../constants/routes';
import {withAuth} from "../../auth/AuthProvider";

const SendMessageForm = ({
                             groupsLoading = false,
                             isMessageSending = false,
                             targetGroupsList = [],
                             handleSubmit = () => {
                             },
                             messageSubmitError = null,
                             auth
                         }) => {
    const globalStyles = styles();

    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [selectedFilesError, setSelectedFilesError] = useState(null);
    const [selectedGroupsError, setSelectedGroupsError] = useState(null);
    const [messageText, setMessageText] = useState("");
    const [messageTextError, setMessageTextError] = useState(null);
    const [totalRecipients, setTotalRecipients] = useState(0);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [isMessageScheduleModalOpen, setIsMessageScheduleModalOpen] = useState(false);

    const resetState = () => {
        setSelectedGroups([]);
        setSelectedGroupsError(null);
        setMessageText("");
        setMessageTextError(null);
        setTotalRecipients(0);
        document.querySelector('input[type="file"]').value = "";
    };

    useEffect(() => {
        resetState();
    }, []);

    useEffect(() => {
        setTotalRecipients(selectedGroups.reduce((prev, group) => prev + group.activeSubscribers, 0))
    }, [selectedGroups])

    const handleChangeSelect = (event, value, reason) => {
        if (selectedGroupsError) setSelectedGroupsError(null);
        setSelectedGroups(value);
    }

    const renderSelectOption = (option, {selected}) => {
        return (
            <FormControlLabel
                control={<Checkbox checked={selected} name={option.groupName}/>}
                label={option.groupName}
            />
        )
    }

    const handleChangeMessageText = e => {
        if (!!messageTextError) setMessageTextError(null);
        setMessageText(e.target.value);
    }

    // VALIDATE
    const validate = () => {
        let valid = true;
        const input = document.querySelector('input[type="file"]').files[0];
        if (input !== undefined) {
            const FileSize = input.size / 1024 / 1024;
            if (FileSize > 1) {
                setSelectedFilesError("Image size must be less than 1MB")
            }
        }

        if (!selectedGroups || selectedGroups.length === 0) {
            valid = false;
            setSelectedGroupsError("Please select at least one notification group")
        }

        if (!messageText || messageText.length === 0) {
            valid = false;
            setMessageTextError("Please enter a valid message")
        }

        if (messageText.length > 1560) {
            valid = false;
            setMessageTextError("Message must be 1560 characters or less")
        }


        if (valid) return valid;
    }

    const handleCancelSubmit = () => setIsConfirmationDialogOpen(false);

    const submit = (scheduled, dateTime) => {
        handleSubmit({
            groupIds: selectedGroups.map(({groupId}) => groupId).join(","),
            message: messageText,
            scheduled: scheduled,
            scheduledDateTime: dateTime,
            requestedBy: auth.account.name,
            file: document.querySelector('input[type="file"]').files[0],
        }, resetState);
    }

    const handleCloseMessageScheduleModal = (event, reason) => {
        setIsMessageScheduleModalOpen(false);
    }

    const [confirmFunction, setConfirmFunction] = useState(() => {
    });
    const [confirmationDialogText, setConfirmationDialogText] = useState(`This message will be sent to ${totalRecipients} subscribers. Send it?`)

    const sendNow = () => {
        // validate
        if (validate()) {
            // open confirmation
            setConfirmationDialogText(`This message will be sent to ${totalRecipients} subscribers. Send it now?`);
            setConfirmFunction(() => () => {
                submit(false, moment(new Date()).format('MM-DD-YYYY HH:mm:ss'));
                // resetState();
                setIsConfirmationDialogOpen(false);
            });
            setIsConfirmationDialogOpen(true);
        }
    }

    const scheduleMessage = dateTime => {
        // validate
        if (validate()) {
            // open confirmation
            const newDateTime = new Date(dateTime)
            const cstDateTime = moment(newDateTime).format("MM/DD/YYYY h:mm A");
            setConfirmationDialogText(`Schedule message for ${cstDateTime} CST?`);
            setConfirmFunction(() => () => {
                submit(true, dateTime);
                // resetState();
                setIsConfirmationDialogOpen(false);
                setIsMessageScheduleModalOpen(false);
            });
            setIsConfirmationDialogOpen(true);
        }
    }
    const onChangeHandler = event => {
        setSelectedFiles(event.target.files[0])
    }
    return (
        <>
            <ConfirmationDialog
                title="Confirm Message"
                isOpen={isConfirmationDialogOpen}
                text={confirmationDialogText}
                handleCancel={handleCancelSubmit}
                handleConfirm={confirmFunction}
            />
            <MessageScheduleModal
                isOpen={isMessageScheduleModalOpen}
                handleClose={handleCloseMessageScheduleModal}
                handleScheduleMessage={scheduleMessage}
            />
            <Typography className={globalStyles.messagingGroupsAll}>Send a Message</Typography>
            <Paper>
                <div className={globalStyles.sendMessageInner}>
                    <Grid container direction="column" spacing={6}>
                        <Grid item container direction="column" spacing={2}>
                            <Grid item container direction="row" justify="space-between" alignItems="flex-end">
                                <Grid item>
                                    <Typography className={globalStyles.sendMessageSectionHeader}>Select a target
                                        audience</Typography>
                                    <FormHelperText
                                        error>{!!selectedGroupsError && selectedGroupsError}</FormHelperText>
                                </Grid>
                                <Grid item>
                                    <Typography className={globalStyles.sendMessageTotalRecipients}>Total
                                        Recipients: {totalRecipients}</Typography>
                                </Grid>
                            </Grid>
                            <FormControl
                                fullWidth
                                error={!!selectedGroupsError}
                                variant="outlined"
                                required>
                                {groupsLoading && <LinearProgress/>}
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    disabled={groupsLoading}
                                    onChange={handleChangeSelect}
                                    value={selectedGroups}
                                    id="targetGroupsSelect"
                                    options={targetGroupsList}
                                    getOptionLabel={option => option.groupName}
                                    ChipProps={{color: "primary", variant: "outlined", size: "small"}}
                                    label={groupsLoading ? "Loading..." : ""}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                        />)}
                                    renderOption={renderSelectOption}
                                />
                                <FormHelperText>{!!selectedGroupsError && selectedGroupsError}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item container direction="column" spacing={2}>
                            <Grid item container direction="column">
                                <Typography className={globalStyles.sendMessageSectionHeader}>Create a
                                    message</Typography>
                                <FormHelperText error>{!!messageTextError && messageTextError}</FormHelperText>
                            </Grid>
                            <Grid item container direction="row" spacing={4}>
                                <Grid item xs={8} container direction="column" spacing={2}>
                                    <Grid item>
                                        <TextField
                                            style={{
                                                backgroundColor: "white",
                                            }}
                                            fullWidth
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            value={messageText}
                                            onChange={handleChangeMessageText}
                                        />
                                    </Grid>
                                    <Grid item container direction="row" justify="space-between"
                                          alignItems="flex-start">
                                        <Grid item xs={6} container direction="row" justify="flex-start"
                                              alignItems="center" spacing={1}>
                                            <Grid item><AttachFileIcon
                                                className={globalStyles.sendMessageAddImageIcon}/></Grid>
                                            <input type="file" name="pic" accept=".png, .jpg, .jpeg"
                                                   onChange={onChangeHandler}></input>
                                            <FormHelperText error>{selectedFilesError}</FormHelperText>
                                            <Typography className={globalStyles.imageHelperText}>Image size should be less than 1 MB</Typography>
                                        </Grid>
                                        <Grid item container xs={6} alignItems="flex-start" justify="flex-end">
                                            <FormHelperText error={messageText.length > 1560}
                                                            className={globalStyles.sendMessageCharacterCount}>{messageText.length} of
                                                1560 characters</FormHelperText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} className={globalStyles.sendMessageGuidelines}>
                                    <Typography className={globalStyles.sendMessageGuidelinesHeader}>Message
                                        Guidelines</Typography>
                                    <Typography className={globalStyles.sendMessageGuidelinesText}>
                                        <ul>
                                            <li className={globalStyles.sendMessageGuidelinesTextBullets}><em>DO
                                                NOT</em> include PHI or confidential Ascension information
                                            </li>
                                            <li className={globalStyles.sendMessageGuidelinesTextBullets}>Limited use of
                                                abbreviations - only use commonly known abbreviations used consistently
                                                across Ascension
                                            </li>
                                        </ul>
                                    </Typography>
                                    <Link to={routes.MESSAGE_GUIDELINES} className={globalStyles.messageGuidelinesLink}>
                                        See all guidelines
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" justify="flex-start" spacing={2}>
                            <Grid item>
                                <Button
                                    disabled={selectedGroups.length === 0 || messageText === ""}
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={sendNow}>
                                    Send Now
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={selectedGroups.length === 0 || messageText === ""}
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    onClick={() => setIsMessageScheduleModalOpen(true)}>
                                    Schedule
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {messageSubmitError && <FormHelperText error>{messageSubmitError}</FormHelperText>}
                        </Grid>
                    </Grid>
                </div>
                {isMessageSending && <LinearProgress/>}
            </Paper>
        </>
    )
}

export default withAuth(SendMessageForm);