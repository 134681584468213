import React from 'react';
import axios from 'axios';
import {getEnvVars} from '../utils/env-utils';

var theEnv = getEnvVars(window.location.host);

const withAxios = Component => props => {
    const { auth } = props;

    const instance = axios.create({
        headers: {
            Authorization: `Bearer ${auth.baseAccessToken}`,
            apikey: theEnv.REACT_APP_API_KEY_URI,
        }
    });

    instance.interceptors.response.use(response => {
        // console.log({response});
        return response
    }, error => {
        if (error.response && error.response.status === 401) auth.logout();
        // console.log({error})
        return Promise.reject(error)
    });

    return <Component {...props} axios={instance} />
};

export default withAxios;
 