import React, {useState} from 'react';
import {styles} from '../../Stylesheet.css';
import {
    Grid,
    Typography,
    FormControl,
    FormControlLabel,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    RadioGroup,
    Radio,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import reportTypes from './ReportTypes';

const ReportTypeForm = ({
                            isExpanded = true,
                            toggleExpanded = () => {
                            },
                            handleChange = () => {
                            },
                        }) => {

    const globalStyles = styles();

    const [reportType, setReportType] = useState("");

    const handleChangeReportType = ({target: {value}}) => {
        setReportType(value);
        handleChange(value);
    }

    return (
        <Grid container className={globalStyles.tableHeaders} style={{marginTop: "30px"}}>
            <Grid item xs={12}>
                <ExpansionPanel
                    className={globalStyles.tableHeaders}
                    expanded={isExpanded}
                    onChange={toggleExpanded}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}>
                        <Typography className={globalStyles.tableHeader}>Select Report</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <FormControl component="fieldset" fullWidth>
                            <RadioGroup
                                value={reportType}
                                onChange={handleChangeReportType}>
                                {Object.keys(reportTypes).map(type => (
                                    <FormControlLabel
                                        key={`report-type-radio-${type}`}
                                        value={reportTypes[type].value}
                                        label={reportTypes[type].label}
                                        control={<Radio color="primary"/>}/>
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        </Grid>
    )
}

export default ReportTypeForm;
