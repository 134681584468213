import React from 'react';
import {styles} from '../../Stylesheet.css';
import MaterialTable from 'material-table';
import {Paper, Typography} from '@material-ui/core';
import Navbar from "../common/Navbar";
import Container from "@material-ui/core/Container";
import {getEnvVars} from '../utils/env-utils';
import {withAuth} from "../../auth/AuthProvider";
import withAxios from '../common/withAxios';

var theEnv = getEnvVars(window.location.host);

const MessageHistory = ({axios}) => {
    const globalStyles = styles();
    
    return (
        <Container style={{paddingTop: "30px", paddingBottom: "30px", paddingLeft: "0px", paddingRight: "0px"}}>
            <Navbar/>
            <Typography className={globalStyles.pageHeaders}>Group Message History</Typography>
            <Paper className={globalStyles.tableTitles} style={{margin: "30px 0"}} elevation={5}>
                <MaterialTable
                    title=""
                    options={{
                        draggable: false,
                        headerStyle: {
                            color: "#1B4297",
                            fontFamily: "Whitney, sans-serif",
                            fontSize: "18px",
                            fontWeight: "600",
                        },
                        cellStyle: {
                            maxWidth: "100px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        },

                    }}

                    columns={[
                        {field: "targetGroup", title: "Target Group"},
                        {field: "messageText", title: "Message Text"},
                        {field: "recipientCount", title: "Recipients"},
                        {field: "publisher", title: "Publisher"},
                        {field: "dateTime", title: "Date Sent (UTC)"},
                    ]}
                    data={query =>
                        new Promise((resolve, reject) => {
                            axios.post(theEnv.REACT_APP_GET_MESSAGES_HISTORY_API, {

                                    pageSize: query.pageSize,
                                    pageNumber: query.page

                            })
                                .then(result => {
                                    console.log({result})
                                    resolve({
                                        data: result.data.content,
                                        page: query.page,
                                        totalCount: result.data.totalElements,
                                    })
                                }).catch(e => {
                                console.error(e);
                                reject();
                            })
                        })
                    }
                    detailPanel={[
                        {
                            tooltip: 'Click here to see the entire message',
                            render: rowData => {
                                return (
                                    <div
                                        style={{
                                            fontSize: 15,
                                            paddingTop: "20px",
                                            paddingBottom: "20px",
                                            color: '#1B4297',
                                            border: 'none',
                                            backgroundColor: '#EFF4FA',
                                            paddingLeft: '58px',
                                        }}
                                    >
                                        {rowData.messageText}
                                    </div>
                                )
                            },
                        },
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default withAuth(withAxios(MessageHistory));