/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {styles} from '../../Stylesheet.css';
import {
    Grid,
    Typography,
    Select,
    TextField,
    FormControl,
    FormLabel,
    FormHelperText,
    Button,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    InputLabel,
    MenuItem,
    LinearProgress,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import reportTypes from './ReportTypes';
import {getEnvVars} from '../utils/env-utils';
import {withAuth} from "../../auth/AuthProvider";
import withAxios from '../common/withAxios';

var theEnv = getEnvVars(window.location.host);
const subscriberStatuses = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
}

const reportPeriods = {
    DAYS: "DAYS",
    WEEKS: "WEEKS",
    MONTHS: "MONTHS"
}

const reportFrequencies = {
    PERIODIC: "PERIODIC",
    CUMULATIVE: "CUMULATIVE"
}

const renderSelectOption = (option, {selected}) => {
    return (
        <FormControlLabel
            control={<Checkbox checked={selected} name={option}/>}
            label={option}
        />
    )
}

const ReportsFilterForm = ({
    axios,
    availableStates = [],
    isExpanded = true,
    reportType = "",
    toggleExpanded = () => {
    },
    handleSubmit = () => {
    },
    isLoading = false,
}) => {

    const globalStyles = styles();

    const [selectedStates, setSelectedStates] = useState([]);
    const [selectedStatesError, setSelectedStatesError] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedGroupsError, setSelectedGroupsError] = useState(null);
    const [subscriberStatus, setSubscriberStatus] = useState("");
    const [subscriberStatusError, setSubscriberStatusError] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [startDateError, setStartDateError] = useState(null);
    const [endDate, setEndDate] = useState("");
    const [endDateError, setEndDateError] = useState(null);
    const [reportPeriod, setReportPeriod] = useState(reportPeriods.DAYS);
    const [reportPeriodError, setReportPeriodError] = useState(null);
    const [reportFrequency, setReportFrequency] = useState(reportFrequencies.PERIODIC);
    const [reportFrequencyError, setReportFrequencyError] = useState(null);
    const [availableGroups, setAvailableGroups] = useState([]);
    const [showTotalSubscriberReport, setShowTotalSubscriberReport] = useState(true);
    const [showSubscriberCompleteDetails, setShowSubscriberCompleteDetails] = useState(true);
    const [subscriberTableSelectionError, setSubscriberTableSelectionError] = useState(null);
    const [groupCreationTableSelectionError, setGroupCreationTableSelectionError] = useState(null);
    const [showTotalGroupsReport, setShowTotalGroupsReport] = useState(true);
    const [showGroupCreationDetails, setShowGroupCreationDetails] = useState(true);

    const [numberActiveFilters, setNumberActiveFilters] = useState(0);
    const [submitError, setSubmitError] = useState(null);

    const handleChangeStartDate = ({target: {value}}) => {
        setStartDate(value);
        if (endDate === "") setEndDate((new Date()).toISOString().slice(0, 10));
    };

    const handleChangeEndDate = ({target: {value}}) => {
        setEndDate(value);
        if (startDate === "") setStartDate(value);
    };

    const clearFilters = () => {
        setNumberActiveFilters(0);
        setSelectedStates([]);
        setSelectedGroups([]);
        setSubscriberStatus("");
        setStartDate("");
        setEndDate("");
        setReportPeriod(reportPeriods.DAYS);
        setReportFrequency(reportFrequencies.PERIODIC);
        setSelectedStatesError(null);
        setSelectedGroupsError(null);
        setSubscriberStatusError(null);
        setStartDateError(null);
        setEndDateError(null);
        setSubmitError(null);
        setReportPeriodError(null);
        setReportFrequencyError(null);
    }

    useEffect(() => {
        clearFilters();
        setShowTotalSubscriberReport(true);
        setShowSubscriberCompleteDetails(true);
        setShowGroupCreationDetails(true);
        setShowTotalGroupsReport(true);
    }, [reportType]);

    const fetchGroups = selectedStates => axios.post(theEnv.REACT_APP_GROUPS_BY_STATES_API_URI,
        {selectedStates}
    )

    useEffect(() => {
        setSelectedGroupsError(null);
        if (selectedStates.length >= 0) {
            fetchGroups(selectedStates)
                .then(({data}) => {
                    setAvailableGroups(data.map(({groupName})=>groupName));

                    if (data.length === 0) {
                        setSelectedGroupsError("Could not find any groups for selected states");
                    }
                })
                .catch(e => console.error(e));
        }
    }, [selectedStates])

    const handleChangeSelectState = (event, value, reason) => {
        if (selectedStatesError) setSelectedStatesError(null);
        setSelectedStates(value);
    }

    const handleChangeSelectGroup = (event, value, reason) => {
        if (selectedGroupsError) setSelectedGroupsError(null);
        setSelectedGroups(value);
    }

    const handleSubmitFilters = () => {
        setSelectedStatesError(null);
        setSelectedGroupsError(null);
        setSubscriberStatusError(null);
        setStartDateError(null);
        setEndDateError(null);
        setSubmitError(null);
        setReportPeriodError(null);
        setReportFrequencyError(null);

        let valid = true;

        // Subscriber status validation
        if (subscriberStatus.trim() !== "") {
            if (!Object.values(subscriberStatuses).includes(subscriberStatus)) {
                valid = false;
                setSubscriberStatusError("Status must be selected from dropdown");
            }
        }

        // Start/end date validation
        const today = (new Date()).toISOString().slice(0, 10);
        if (startDate.trim() !== "") {
            if (startDate > today) {
                valid = false;
                setStartDateError("Start Date cannot be in the future")
            } else if (endDate.trim() === "") {
                valid = false;
                setEndDateError("Please provide both a start and end date")
            }
        }
        if (endDate.trim() !== "") {
            if (endDate > today) {
                valid = false;
                setEndDateError("End Date cannot be in the future")
            } else if (startDate.trim() === "") {
                valid = false;
                setStartDateError("Please provide both a start and end date")
            }
        }
        if (startDate.trim() !== "" &&
            endDate.trim() !== "" &&
            endDate < startDate) {
            valid = false;
            setStartDateError("Start Date must be before End Date")
            setEndDateError("End Date must be after Start Date")
        }
        if (reportType !== "GROUP_CREATION") {
            if (!selectedGroups || selectedGroups.length === 0) {
                valid = false;
                setSelectedGroupsError("Please select at least one notification group")
            }
        }
        if (reportType === "GROUP_CREATION") {
            if (!selectedStates || selectedStates.length === 0) {
                valid = false;
                setSelectedStatesError("Please select at least one state")
            }
        }
        if (!startDate || startDate.length === 0) {
            valid = false;
            setStartDateError("Please select a valid start date")
        }
        if (!endDate || endDate.length === 0) {
            valid = false;
            setEndDateError("Please select a valid end date")
        }
        if (!showTotalSubscriberReport && !showSubscriberCompleteDetails) {
            valid = false;
            setSubscriberTableSelectionError("Please select at least one table to display");
        }

        if (valid) {
            let filters = {}
            if (reportType === "DELIVERY_STATUS") filters.reportType = "MessageDeliveryReport";
            else if (reportType === "SUBSCRIBERS") filters.reportType = "SubscriberReport";
            else if (reportType === "GROUP_CREATION") filters.reportType = "groupCreationReport";
            else filters.reportType = "SummaryReport";
            if (selectedStates && selectedStates.length > 0) filters.states = selectedStates;
            if (selectedGroups && selectedGroups.length > 0 && reportType !== "GROUP_CREATION") filters.groupName = selectedGroups;
            if (subscriberStatus.trim() !== "") filters.status = subscriberStatus.trim();
            if (startDate.trim() !== "") filters.startDate = startDate.trim();
            if (endDate.trim() !== "") filters.endDate = endDate.trim();
            if (reportPeriod.trim() !== "") filters.period = reportPeriod.trim();
            if (reportFrequency.trim() !== "") filters.frequency = reportFrequency.trim();

            const numFilters = Object.keys(filters).length;

            if (numFilters === 0) setSubmitError("Please select at least one filter");
            else {
                setNumberActiveFilters(numFilters);
                handleSubmit(filters, showTotalSubscriberReport, showSubscriberCompleteDetails, showGroupCreationDetails, showTotalGroupsReport);
            }
        }
    }

    return (
        <Grid container style={{marginTop: "30px"}}>
            <Grid item xs={12}>
                <ExpansionPanel
                    expanded={isExpanded}
                    onChange={toggleExpanded}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon/>}>
                        <Typography
                            className={globalStyles.tableHeader}>Select {reportTypes[reportType].label} Filters</Typography>
                    </ExpansionPanelSummary>
                    {reportType === reportTypes.GROUP_CREATION.value &&
                    <Typography className={globalStyles.tableinfo}>
                        *** In selecting date ranges, please use full weeks or months ***
                    </Typography>}
                    {reportType === "" ?
                        <ExpansionPanelDetails>
                            <FormControl
                                error
                                fullWidth>
                                <FormLabel>Please select a Report Type</FormLabel>
                            </FormControl>
                        </ExpansionPanelDetails> :
                        <ExpansionPanelDetails>
                            <Grid container direction="column">
                                {!!submitError && <FormHelperText error>{submitError}</FormHelperText>}
                                <Grid item container spacing={4}>
                                    <Grid item container direction="row" spacing={4}>
                                        <Grid item xs={4}>
                                            <FormControl
                                                fullWidth
                                                style={{margin: "10px 0"}}
                                                error={!!selectedStatesError}
                                                required={reportType === reportTypes.GROUP_CREATION.value}>
                                                <FormLabel>State(s)</FormLabel>
                                                <Autocomplete
                                                    multiple
                                                    native
                                                    disableCloseOnSelect
                                                    onChange={handleChangeSelectState}
                                                    id="statessSelect"
                                                    value={selectedStates}
                                                    options={availableStates}
                                                    ChipProps={{color: "primary", variant: "outlined", size: "small"}}
                                                    renderOption={renderSelectOption}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            placeholder={selectedStates.length > 0 ? "" : "All States"}
                                                        />)}
                                                />
                                                <FormHelperText>{!!selectedStatesError && selectedStatesError}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        {reportType !== reportTypes.GROUP_CREATION.value &&
                                        <Grid item xs={8}>
                                            <FormControl
                                                fullWidth
                                                style={{margin: "10px 0"}}
                                                error={!!selectedGroupsError}
                                                required>
                                                <FormLabel>Messaging Group(s)</FormLabel>
                                                <Autocomplete
                                                    multiple
                                                    native
                                                    disableCloseOnSelect
                                                    onChange={handleChangeSelectGroup}
                                                    id="groupsSelect"
                                                    value={selectedGroups}
                                                    options={availableGroups}
                                                    ChipProps={{color: "primary", variant: "outlined", size: "small"}}
                                                    renderOption={renderSelectOption}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                        />)}
                                                />
                                                <FormHelperText>{!!selectedGroupsError && selectedGroupsError}</FormHelperText>
                                            </FormControl>
                                        </Grid>}
                                    </Grid>
                                    <Grid item container direction="row" spacing={4}>
                                        <Grid item xs={4}>
                                            <FormControl
                                                style={{margin: "10px 0"}}
                                                fullWidth>
                                                <FormLabel>Start Date</FormLabel>
                                                <TextField
                                                    placeholder="All Time"
                                                    id="startDateText"
                                                    type="date"
                                                    value={startDate}
                                                    onChange={handleChangeStartDate}/>
                                                {!!startDateError &&
                                                <FormHelperText error>{startDateError}</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl
                                                style={{margin: "10px 0"}}
                                                fullWidth>
                                                <FormLabel>End Date</FormLabel>
                                                <TextField
                                                    placeholder="All Time"
                                                    id="endDateText"
                                                    type="date"
                                                    value={endDate}
                                                    onChange={handleChangeEndDate}/>
                                                {!!endDateError &&
                                                <FormHelperText error>{endDateError}</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl
                                                style={{margin: "10px 0"}}
                                                fullWidth>
                                                <InputLabel>Period</InputLabel>
                                                <Select
                                                    id="periodSelect"
                                                    value={reportPeriod}
                                                    onChange={e => setReportPeriod(e.target.value)}
                                                >
                                                    <MenuItem value={reportPeriods.DAYS}>Days</MenuItem>
                                                    <MenuItem value={reportPeriods.WEEKS}>Weeks (Partial weeks will not be rendered)</MenuItem>
                                                    <MenuItem value={reportPeriods.MONTHS}>Months (Partial months will not be rendered)</MenuItem>

                                                </Select>
                                                {!!reportPeriodError &&
                                                <FormHelperText error>{reportPeriodError}</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="row" spacing={4}>
                                        <Grid item xs={4}>
                                            <FormControl
                                                style={{margin: "10px 0"}}
                                                fullWidth>
                                                <InputLabel>Frequency</InputLabel>
                                                <Select
                                                    id="frequencySelect"
                                                    value={reportFrequency}
                                                    onChange={e => setReportFrequency(e.target.value)}
                                                >
                                                    <MenuItem value={reportFrequencies.PERIODIC}>Periodic</MenuItem>

                                                </Select>
                                                {!!reportFrequencyError &&
                                                <FormHelperText error>{reportFrequencyError}</FormHelperText>}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {reportType === reportTypes.SUBSCRIBERS.value &&
                                    <Grid item container direction="column" spacing={1}>
                                        <Grid item>
                                            {!!subscriberTableSelectionError &&
                                            <FormHelperText error>{subscriberTableSelectionError}</FormHelperText>}
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    color="primary"
                                                    checked={showTotalSubscriberReport}
                                                    onChange={() => {
                                                        setSubscriberTableSelectionError(null);
                                                        setShowTotalSubscriberReport(!showTotalSubscriberReport);
                                                    }}
                                                    name="showTotalSubscriberReport"
                                                />}
                                                label="Total Subscriber Report"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    color="primary"
                                                    checked={showSubscriberCompleteDetails}
                                                    onChange={() => {
                                                        setSubscriberTableSelectionError(null);
                                                        setShowSubscriberCompleteDetails(!showSubscriberCompleteDetails);
                                                    }}
                                                    name="showSubscriberCompleteDetails"
                                                />}
                                                label="Subscriber Complete Details"
                                            />
                                        </Grid>
                                    </Grid>}
                                    {reportType === reportTypes.GROUP_CREATION.value &&
                                    <Grid item container direction="column" spacing={1}>
                                        <Grid item>
                                            {!!groupCreationTableSelectionError &&
                                            <FormHelperText error>{groupCreationTableSelectionError}</FormHelperText>}
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    color="primary"
                                                    checked={showTotalGroupsReport}
                                                    onChange={() => {
                                                        setGroupCreationTableSelectionError(null);
                                                        setShowTotalGroupsReport(!showTotalGroupsReport);
                                                    }}
                                                    name="showTotalGroupsReport"
                                                />}
                                                label="Group Creation Complete Details"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    color="primary"
                                                    checked={showGroupCreationDetails}
                                                    onChange={() => {
                                                        setGroupCreationTableSelectionError(null);
                                                        setShowGroupCreationDetails(!showGroupCreationDetails);
                                                    }}
                                                    name="showGroupCreationDetails"
                                                />}
                                                label="Total Groups Report"
                                            />
                                        </Grid>
                                    </Grid>}
                                </Grid>
                                <Grid item container direction="column">
                                    <Grid item container justify="flex-end" spacing={2}>
                                        <Grid item>
                                            <Button
                                                color="secondary"
                                                onClick={clearFilters}>
                                                Clear Filters
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmitFilters}>
                                                Run Reports
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>}
                    {isLoading && <LinearProgress/>}
                </ExpansionPanel>
            </Grid>
        </Grid>
    )
}

export default withAuth(withAxios(ReportsFilterForm));
