/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {styles} from '../../Stylesheet.css';
import {useHistory} from 'react-router-dom';
import MaterialTable from 'material-table';
import {Container, Paper, Typography} from '@material-ui/core';
import Navbar from "../common/Navbar";
import GroupInfo from "../GroupsDashboard/GroupInfo";
import Button from '@material-ui/core/Button';
import MessagesList from '../MessagesDashboard/MessagesList';
import {getEnvVars} from '../utils/env-utils';
import {withAuth} from "../../auth/AuthProvider";
import withAxios from '../common/withAxios';

var theEnv = getEnvVars(window.location.host);
const SubscribersListController = ({axios}) => {
    const globalStyles = styles();
    const history = useHistory();
    const path = history.location.pathname;
    const groupId = path.substring(path.lastIndexOf("/") + 1);

    const [subscribersList, setSubscribersList] = useState([]);

    useEffect(() => {
        axios.get(`${theEnv.REACT_APP_NOTIFICATION_GET_GROUPS_API_URI}/${groupId}/subscribers`)
            .then(subscribersRes => {
                if (subscribersRes) setSubscribersList(subscribersRes.data)
            })
            .catch (e => console.error(e));
    }, [groupId]);

    return (
        <Container style={{paddingTop: "30px", paddingBottom: "30px", marginTop: "40px", marginBottom: "40px"}}>
            <Navbar/>
            <GroupInfo/>
            <MessagesList
                title="Message History"
                data={
                    query =>
                        new Promise((resolve, reject) => {
                            axios.post(theEnv.REACT_APP_MESSAGE_BROADCAST_API_URI, {
                                pageSize: query.pageSize,
                                pageNumber: query.page,
                                currentGroupId: groupId
                            })
                                .then(result => {
                                    console.log({result})
                                    resolve({
                                        data: result.data.content,
                                        page: query.page,
                                        totalCount: result.data.totalElements,
                                    })
                                }).catch(e => {
                                console.error(e);
                                reject();
                            })
                        })
                }
                editable={false}
            />

            <Typography className={globalStyles.tableTitle}>Subscribers List</Typography>
            <Paper>
                <MaterialTable
                    title=""
                    data={subscribersList}
                    options={{
                        draggable: false,
                        headerStyle: {
                            height: "21px",
                            width: "184px",
                            color: "#1B4297",
                            fontFamily: "Whitney",
                            fontSize: "18px",
                            fontWeight: "600",
                            letterSpacing: 0,
                            lineHeight: "21px",
                        },
                        cellStyle: {
                            maxWidth: "100px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        },
                    }}
                    columns={[
                        {title: "S. No", render: rowData => rowData.tableData.id + 1},
                        {
                            field: "subscriberNumber", title: "Subscriber Number",
                            render: rowData => {
                                return (
                                    rowData.subscriberNumber.slice(0, 0) + rowData.subscriberNumber.slice(2).replace(/.(?=....)/g, '*')
                                )
                            }
                        },
                        {title: "Name", render: rowData => `${rowData.firstName} ${rowData.lastName}`},
                        {field: "status", title: "Status"},
                    ]}
                />
            </Paper>
            <Button style={{marginTop: 40, marginBottom: 80, float: 'right', background: 'white'}} variant="outlined"
                    size="small" color="primary">Archive Group</Button>
        </Container>
    )
}

export default withAuth(withAxios(SubscribersListController));
