/* eslint-disable */
import React from 'react';
import {styles} from '../../Stylesheet.css'
import MaterialTable from "material-table";
import _mapValues from "lodash/mapValues";

const MessageDeliveryTable = ({tableData = {}}) => {

    const globalStyles = styles();
    const parsedData = tableData.data.reduce((acc, {date, perc, groupName, totaldaycount, failed, state}, index, array) => {
        const sumArray = (a1, a2) => [a1[0] + a2[0], a1[1] + a2[1], a1[2] + a2[2]];
        if (!acc.headers.some(v => v.title === date)) acc.headers.push({
            title: date, field: date,
            render: rowData => {
                return rowData['state-group'] === 'Total' ?
                    <div>
                        <span style={{color: 'grey', fontWeight: 'bold'}}>{rowData[date][0]}</span><br/>
                        <span style={{color: 'red', fontWeight: 'bold'}}>{rowData[date][1]}</span><br/>
                        <span style={{
                            color: 'green',
                            fontWeight: 'bold'
                        }}>{Math.round((rowData[date][2] + Number.EPSILON) * 100) / 100}</span>
                    </div> :
                    <div>
                        <span style={{color: 'grey'}}>{rowData[date][0]}</span><br/>
                        <span style={{color: 'red'}}>{rowData[date][1]}</span><br/>
                        <span
                            style={{color: 'green'}}>{Math.round((rowData[date][2] + Number.EPSILON) * 100) / 100}</span>
                    </div>
            }
        })

        if (!acc.data.some(v => v.id === state))
            acc.data.push({
                id: state,
                'state-group': state,
                'delivery-status': ['Total Sent', 'Total Undelivered', '% Successful']
            })
        const parentIndex = acc.data.findIndex(v => v.id === state)
        if (!acc.data[parentIndex][date]) acc.data[parentIndex][date] = [totaldaycount, failed, perc]
        else acc.data[parentIndex][date] = sumArray(acc.data[parentIndex][date], [totaldaycount, failed, perc])
        if (!acc.data[parentIndex].total) {
            const stateTotals = tableData.stateTotalCounter.find(g => g.state === state);
            acc.data[parentIndex].total = [
                stateTotals.totalDayCountSum,
                stateTotals.failedSum,
                stateTotals.percSum,
            ]
        }

        if (!acc.data.some(v => v.id === groupName))
            acc.data.push({
                id: groupName,
                'state-group': groupName,
                parentId: state,
                'delivery-status': ['Total Sent', 'Total Undelivered', '% Successful']
            })
        const groupIndex = acc.data.findIndex(v => v.id === groupName)
        acc.data[groupIndex][date] = [totaldaycount, failed, perc]
        if (!acc.data[groupIndex].total) {
            const groupTotals = tableData.groupTotalCounter.find(a => a.groupName === groupName);
            acc.data[groupIndex].total = [
                groupTotals.totalDayCountSum,
                groupTotals.failedSum,
                groupTotals.percSum,
            ]
        }

        const totoalRowId = 'zzzz'
        if (!acc.data.some(v => v.id === totoalRowId))
            acc.data.push({
                id: totoalRowId,
                'state-group': 'Total',
                'delivery-status': ['Total Sent', 'Total Undelivered', '% Successful']
            })
        const totalIndex = acc.data.findIndex(v => v.id === totoalRowId)

        if (!acc.data[totalIndex][date]) {
            const {dayTotal, dayTotalFailed, dayTotalPerc} = tableData.dayCounter.find(v => v.date === date)
            acc.data[totalIndex][date] = [dayTotal, dayTotalFailed, dayTotalPerc]
        }

        if (index === array.length - 1) {
            acc.headers.push({
                title: "Total",
                render: rowData => <div><span style={{color: 'grey', fontWeight: 'bold'}}>{rowData.total[0]}</span><br/>
                    <span style={{color: 'red', fontWeight: 'bold'}}>{rowData.total[1]}</span><br/>
                    <span style={{
                        color: 'green',
                        fontWeight: 'bold'
                    }}>{Math.round((rowData.total[2] + Number.EPSILON) * 100) / 100}</span></div>
            })
            const { dayTotalPerc, dayTotalFailed, dayTotal } = tableData.totalTotalCounter;
            acc.data[totalIndex].total = [
                dayTotal,
                dayTotalFailed,
                dayTotalPerc,
            ]
        }

        return acc
    }, {
        headers: [
            {
                title: 'State/Group Name', field: 'state-group',
                render: rowData => {
                    return rowData.parentId == null ?
                        <div style={{'font-weight': 'bold'}}>{rowData['state-group']}</div> :
                        <div style={{'font-weight': 'normal'}}>{rowData['state-group']}</div>
                }
            },
            {
                title: 'Delivery Status', field: 'delivery-status',
                render: rowData => <div><span style={{color: 'black'}}>{rowData['delivery-status'][0]}</span><br/>
                    <span style={{color: 'red'}}>{rowData['delivery-status'][1]}</span><br/>
                    <span style={{color: 'green'}}>{rowData['delivery-status'][2]}</span></div>
            }
        ], data: []
    })


    let {headers, data} = parsedData;
    const customSort = (a, b) => {
        if (a.id < b.id) {
            return -1;
        }
        if (a.id > b.id) {
            return 1;
        }
        return 0;
    }
    data = data.sort(customSort)
        .map((item, i, arr) => {
            if (item.id !== 'zzzz' && !item.parentId) {
                const groupsLength = arr.filter(v => v.parentId === item.id).length;

                return _mapValues(item, (value, key) => {

                    if (!['state-group', 'id', 'delivery-status'].includes(key)) {
                        if (item[key][0] !== 0 && item[key][1] === 0) {
                            return [item[key][0], item[key][1], 100]
                        } else if (item[0] !== 0 && item[1] !== 0) {
                            return [item[key][0], item[key][1], (item[key][0] - item[key][1]) / item[key][0] * 100]
                        } else if (item[key][0] === 0 && item[key][1] === 0) {
                            return [item[key][1], item[key][0], 0]
                        }
                    } else return item[key];
                })
            }
            return item;
        })

    return (
        <>
            <MaterialTable
                style={{marginTop: "30px"}}
                title=""
                data={data}
                columns={headers}
                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                options={
                    {
                        paging: false,
                        sorting: false,
                        maxBodyHeight: '50vh',
                        headerStyle: {
                            background: "linear-gradient(to right, #1b4297, #8490d9)",
                            font: "Whitney, sans-serif",
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#ffffff",
                            whiteSpace: "nowrap",
                            // minWidth: "160px",
                        },
                        rowStyle: rowData => ({
                            backgroundColor: (rowData.id === 'zzzz') ? '#C8C8C8' : '#FFF',
                        }),
                    }
                }
            />
        </>
    )
};

export default MessageDeliveryTable;