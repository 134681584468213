import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ThemeProvider} from '@material-ui/core/styles';
import AuthProvider from '../auth/AuthProvider';
import theme from './App.theme';
import store from '../Redux/store';
import routes from '../constants/routes';
import MessagesDashboardController from "../components/MessagesDashboard/MessagesDashboardController";
import MessageGuidelinesPage from "../components/MessagesDashboard/MessageGuidelinesPage";
import MessageHistory from "../components/MessagesDashboard/MessageHistory";
import DistributionDashboard from "../components/GroupsDashboard/GroupsDashboardController";
import SubscribersListController from "../components/SubscribersList/SubscribersListController";
import ReportsDashboardController from '../components/Reports/ReportsDashboardController';
import UserPermissionsDashboard from "../components/Users/UserPermissionsDashboard";
import Navbar from "../components/common/Navbar";
import Header from "../components/common/Header";
import ProfilePage from "../components/common/Profile"

const App = () => (
    <AuthProvider>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <Header/>
                    <Navbar/>

                    <Route
                        exact
                        path={routes.HOME}
                        component={MessagesDashboardController}
                    />
                    <Route path={routes.MESSAGE_HISTORY} component={MessageHistory}/>
                    <Route path={routes.GROUPS} component={DistributionDashboard}/>
                    <Route path={routes.SUBSCRIBERS_LIST} component={SubscribersListController}/>
                    <Route path={routes.REPORTS} component={ReportsDashboardController}/>
                    <Route path={routes.USER_PERMISSIONS} component={UserPermissionsDashboard}/>
                    <Route path={routes.SEND_MESSAGE} component={MessagesDashboardController}/>
                    <Route path={routes.MESSAGE_GUIDELINES} component={MessageGuidelinesPage}/>
                    <Route path={routes.PROFILE} component={ProfilePage}/>
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </AuthProvider>
);
export default App;