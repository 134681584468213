/* eslint-disable */
export default {
  HOME: '/',
  MESSAGE_HISTORY: '/messagehistory',
  GROUPS: '/groups',
  SUBSCRIBERS_LIST: '/subscriberslist',
  REPORTS: '/reports',
  USER_PERMISSIONS: '/userpermissions',
  SEND_MESSAGE: '/sendmessage',
  MESSAGE_GUIDELINES: '/messageguidelines',
  PROFILE: '/profileinfo'
};
