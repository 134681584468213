import React from 'react';
import MaterialTable from "material-table";

const GroupsCountTable = ({tableData = {}}) => {
    const parsedData = tableData.data.reduce((acc, {date, count, state, total}, index, array) => {
        if (!acc.headers.some(v => v.title === date)) acc.headers.push({
            title: date, field: date,
            render: rowData => {
                return rowData['state-group'] === 'Total' ?
                    <div><span style={{fontWeight: 'bold'}}>{rowData[date]}</span><br/></div> :
                    <div><span style={{color: 'grey'}}>{rowData[date]}</span><br/></div>
            }
        })

        if (!acc.data.some(v => v.id === state))
            acc.data.push({
                id: state,
                'state-group': state,
            })
        const parentIndex = acc.data.findIndex(v => v.id === state)
        if (acc.data[parentIndex][date]) acc.data[parentIndex][date] = [count]
        else acc.data[parentIndex][date] = acc.data[parentIndex][date] = [count]

        if (acc.data[parentIndex]) acc.data[parentIndex].total = total;

        const totoalRowId = 'zzzz'
        if (!acc.data.some(v => v.id === totoalRowId))
            acc.data.push({
                id: totoalRowId,
                'state-group': 'Total',
            })
        const totalIndex = acc.data.findIndex(v => v.id === totoalRowId)

        if (!acc.data[totalIndex][date]) {
            const {count} = tableData.dayCount.find(v => v.date === date)
            acc.data[totalIndex][date] = [count]
        }

        if (index === array.length - 1) {
            acc.headers.push({
                title: "Total",
                render: rowData => <div><span style={{fontWeight: 'bold'}}>{rowData.total}</span></div>
            })
            acc.data[totalIndex].total = tableData.dayCount.reduce((a, b) => a + b.count, 0)
        }

        return acc
    }, {
        headers: [
            {title: 'State', field: 'state-group', cellStyle: {fontWeight: 'bold'}},
        ], data: []
    })


    let {headers, data} = parsedData;
    const customSort = (a, b) => {
        if (a.id < b.id) {
            return -1;
        }
        if (a.id > b.id) {
            return 1;
        }
        return 0;
    }
    data = data.sort(customSort)
    return (

        <MaterialTable
            title=""
            data={data}
            columns={headers}
            // parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
            options={
                {
                    draggable: false,
                    paging: false,
                    sorting: false,
                    maxBodyHeight: '40vh',
                    headerStyle: {
                        background: "linear-gradient(to right, #1b4297, #8490d9)",
                        font: "Whitney, sans-serif",
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#ffffff",
                        whiteSpace: "nowrap",
                        // minWidth: "160px",
                    },
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.id === 'zzzz') ? '#C8C8C8' : '#FFF',
                    }),
                }
            }
        />
    )
};

export default GroupsCountTable;