/* eslint-disable */
import React, {useState, useEffect, useRef} from 'react';
import {styles} from '../../Stylesheet.css';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Navbar from "../common/Navbar";
import Typography from "@material-ui/core/Typography";
import UsersList from "./UsersList";
import UsersModal from "./UsersModal";
import {getEnvVars} from '../utils/env-utils';
import {withAuth} from "../../auth/AuthProvider";
import withAxios from '../common/withAxios';

var theEnv = getEnvVars(window.location.host);

const sortByGroupName = (a, b) => {
    if (a.groupName < b.groupName) return -1;
    else if (a.groupName === b.groupName) return 0;
    else return 1;
}

const UserPermissionsDashboard = ({axios}) => {
    const globalStyles = styles();
    
    const [allUsers, setAllUsers] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [notificationGroups, setNotificationGroups] = useState([]);
    const [isUpdateUserModal, setIsUpdateUserModal] = useState(false);

    const [isUsersListLoading, setIsUsersListLoading] = useState(false);
    const [isGroupsListLoading, setIsGroupsListLoading] = useState(false);

    const usersListTableRef = useRef();

    useEffect(() => {
        axios.get(theEnv.REACT_APP_GET_STATES_API)
            .then(({ data }) => setAllStates(data))
            .catch(e => console.error(e))

    }, []);


    //Distribution Modal State
    const [isUsersListModalOpen, setIsUsersListModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("Create New User");
    const [initialState, setInitialState] = useState("");
    const [initialEmail, setInitialEmail] = useState("");
    const [initialFirstName, setInitialFirstName] = useState("");
    const [initialLastName, setInitialLastName] = useState("");
    const [initialRoles, setInitialRoles] = useState([]);
    const [userId, setUserId] = useState(null);
    const [modalSubmitFunction, setModalSubmitFunction] = useState(() => () => {
    })
    const [usersListSubmitError, setUsersListSubmitError] = useState(null);

    // Create New User functions
    const handleCreateNewUser = newUser => {
        setIsUsersListLoading(true);
        setUsersListSubmitError(null);
        axios.post(theEnv.REACT_APP_CREATE_USERS_API_URI, newUser)
            .then(({data}) => {
                handleCloseModal();
                if (usersListTableRef.current) usersListTableRef.current.onQueryChange();
            })
            .catch(e => {
                console.error(e);
                setUsersListSubmitError(e.response ? e.response.data : "An error occurred");
            })
            .finally(() => setIsUsersListLoading(false));
    }

    const handleOpenCreateUsersModal = () => {
        setUsersListSubmitError(null);

        setModalTitle("Create New User");
        setInitialState("");
        setInitialEmail("");
        setInitialFirstName("");
        setInitialLastName("");
        setInitialRoles("");
        setNotificationGroups([]);
        setModalSubmitFunction(() => handleCreateNewUser);
        setIsUpdateUserModal(false);
        setIsUsersListModalOpen(true);
    }

    // Update user functions
    const handleUpdateUser = userId => userData => {
        setIsUsersListLoading(true);
        setUsersListSubmitError(null);
        const updatedUser = {
            userId,
            ...userData
        }
        axios.put(theEnv.REACT_APP_UPDATE_USERS_API_URI, updatedUser)
            .then(({data}) => {
                handleCloseModal();
                if (usersListTableRef.current) usersListTableRef.current.onQueryChange();
            })
            .catch(e => {
                console.error(e);
                setUsersListSubmitError(e.response ? e.response.data : "An error occurred")
            })
            .finally(() => setIsUsersListLoading(false));
    }

    const handleOpenUpdateUsersListModal = userId => {
        setUsersListSubmitError(null);
        setUserId(userId);

        const user = allUsers.find(user => user.userId === userId);

        setModalTitle("Update User");
        setInitialState(user.state);
        setInitialEmail(user.email);
        setInitialFirstName(user.firstName);
        setInitialLastName(user.lastName);
        setNotificationGroups(user.notificationGroupIds);
        setInitialRoles(user.roles.length > 0 ? user.roles[0] : "");
        setModalSubmitFunction(() => handleUpdateUser(userId));
        setIsUpdateUserModal(true);
        setIsUsersListModalOpen(true);

    }

    // Delete User
    const handleDeleteUser = user => {
        setIsUsersListLoading(true);
        setUsersListSubmitError(null);
        const userToDelete = {
            userId,
            roles: user.newRoles
        }

        axios.post(theEnv.REACT_APP_DELETE_USER_API_URI, userToDelete)
            .then((res) => {
                let updatedUsers = [...allUsers];
                const index = updatedUsers.findIndex(u => u.userId === userId)
                updatedUsers = [...updatedUsers.slice(0, index), ...updatedUsers.slice(index + 1)];
                setAllUsers(updatedUsers);
                handleCloseModal();
            })
            .catch(e => {
                console.error(e);
                setUsersListSubmitError(e.message);
            })
            .finally(() => setIsUsersListLoading(false));
    }

    const handleCloseModal = () => {
        setNotificationGroups([]);
        setUserId(null);
        setIsUsersListModalOpen(false);
    }

    return (
        <Container style={{paddingTop: "30px", paddingBottom: "30px", paddingLeft: "0px", paddingRight: "0px"}}>
            <Navbar/>
            <Grid
                container
                direction="row"
                alignItems="center"
            >
                <Typography className={globalStyles.pageHeaders}> System Users </Typography>
                <Button
                    variant="contained"
                    size="large"
                    className={globalStyles.createButtton}
                    style={{marginLeft: 20}}
                    onClick={handleOpenCreateUsersModal}>
                    Create New User
                </Button>
            </Grid>
            <UsersModal
                modalTitle={modalTitle}
                isOpen={isUsersListModalOpen}
                isLoading={isUsersListLoading}
                isGroupsListLoading={isGroupsListLoading}
                isUpdateModal={isUpdateUserModal}
                handleClose={handleCloseModal}
                handleSubmit={modalSubmitFunction}
                handleDeleteUser={handleDeleteUser}
                submitError={usersListSubmitError}
                initialState={initialState}
                initialEmail={initialEmail}
                initialFirstName={initialFirstName}
                initialLastName={initialLastName}
                initialRole={initialRoles}
                initialNotificationGroups={notificationGroups}
                availableStates={allStates}
            />
            <UsersList
                handleEdit={handleOpenUpdateUsersListModal}
                setUsers={setAllUsers}
                tableRef={usersListTableRef}
            />
        </Container>
    )
}

export default withAuth(withAxios(UserPermissionsDashboard));