/* eslint-disable */
import React, {useState, useEffect, useRef} from 'react';
import {styles} from '../../Stylesheet.css';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import GroupsModal from './GroupsModal';
import GroupsList from './GroupsList';
import Navbar from "../common/Navbar";
import Typography from "@material-ui/core/Typography";
import {getEnvVars} from '../utils/env-utils';
import {withAuth} from "../../auth/AuthProvider";
import withAxios from '../common/withAxios';

var theEnv = getEnvVars(window.location.host);

const GroupsDashboard = ({auth, axios}) => {
    const globalStyles = styles();

    const [isGroupModalLoading, setIsGroupModalLoading] = useState(false);
    const [isUserListLoading, setIsUserListLoading] = useState(false);

    const [users, setUsers] = useState([]);

    const [allStates, setAllStates] = useState([]);

    const [isUpdateGroupModal, setIsUpdateGroupModal] = useState(false);

    const tableRef = useRef();

    useEffect(() => {
        axios.get(theEnv.REACT_APP_GET_STATES_API)
            .then(({data}) => {
                setAllStates(data);
            })

    }, []);

    //Distribution Modal State
    const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

    const [modalTitle, setModalTitle] = useState("Create New Notification Group")

    const [initialGroupName, setInitialGroupName] = useState("");
    const [initialDescription, setInitialDescription] = useState("");
    const [initialState, setInitialState] = useState("");

    const [modalSubmitFunction, setModalSubmitFunction] = useState(() => () => {
    })

    const [groupSubmitError, setGroupSubmitError] = useState(null);

    const handleCloseModal = () => {
        setUsers([]);
        setInitialGroupName("");
        setInitialDescription("");
        setInitialState("");
        setGroupSubmitError(null);
        setIsGroupModalOpen(false);
    }

    // Create New Group functions

    const handleCreateNewGroup = newGroup => {
        setIsGroupModalLoading(true);
        setGroupSubmitError(null);
        axios.post(theEnv.REACT_APP_NOTIFICATION_POST_NEW_GROUP_API_URI, newGroup)
            .then(({data}) => {
                if (tableRef.current) tableRef.current.onQueryChange();
                handleCloseModal();
            })
            .catch(e => {
                console.error(e);
                setGroupSubmitError("There was an error contacting the server to create the group. Please try again. If the error persists please contact the application administrator.")
            })
            .finally(() => setIsGroupModalLoading(false));
    }

    const handleOpenCreateGroupModal = () => {
        setIsUpdateGroupModal(false);

        setIsUserListLoading(true);

        setModalTitle("Create New Notification Group");
        setInitialGroupName("");
        setInitialDescription("");
        setInitialState("");
        setUsers([]);
        setModalSubmitFunction(() => handleCreateNewGroup);
        setIsGroupModalOpen(true);
    }

    // Update Group functions

    const handleUpdateGroup = groupId => updatedGroupInfo => {
        setIsGroupModalLoading(true);
        setGroupSubmitError(null);

        const updatedGroup = {groupId, ...updatedGroupInfo};

        axios.post(theEnv.REACT_APP_NOTIFICATION_POST_UPDATE_GROUP_API_URI, updatedGroup)
            .then(({data}) => {
                if (tableRef.current) tableRef.current.onQueryChange();
                handleCloseModal();
            })
            .catch(e => {
                console.error(e);
                setGroupSubmitError("There was an error contacting the server to create the group. Please try again. If the error persists please contact the application administrator.")
            })
            .finally(() => setIsGroupModalLoading(false));

    }

    const handleOpenUpdateGroupModal = group => {
        setIsUpdateGroupModal(true);

        setIsUserListLoading(true);

        setModalTitle("Update Notification Group");
        setInitialGroupName(group.groupName);
        setInitialDescription(group.description);
        setInitialState(group.state);
        setUsers(group.userIds);
        setModalSubmitFunction(() => handleUpdateGroup(group.groupId));
        setIsGroupModalOpen(true);
    }

    return (
        <Container style={{paddingTop: "30px", paddingBottom: "30px", paddingLeft: "0px", paddingRight: "0px"}}>
            <Navbar/>
            <Grid
                container
                direction="row"
                alignItems="center"
            >

                <Typography className={globalStyles.pageHeaders}>Manage Groups</Typography>
                {(auth.account.idToken.user_roles.includes("Nurse_DSSMS_APP_ADMIN") || auth.account.idToken.user_roles.includes("Nurse_DSSMS_GROUP_ADMIN")) &&
                <Button
                    variant="contained"
                    size="large"
                    className={globalStyles.createButtton}
                    style={{marginLeft: 20}}
                    onClick={handleOpenCreateGroupModal}>
                    Create Group
                </Button>}
            </Grid>
            <GroupsModal
                isUpdateModal={isUpdateGroupModal}
                modalTitle={modalTitle}
                isOpen={isGroupModalOpen}
                isLoading={isGroupModalLoading}
                isUserListLoading={isUserListLoading}
                handleClose={handleCloseModal}
                handleSubmit={modalSubmitFunction}
                submitError={groupSubmitError}
                initialGroupName={initialGroupName}
                initialDescription={initialDescription}
                initialState={initialState}
                initialUsers={users}
                availableStates={allStates}
            />
            <GroupsList
                tableRef={tableRef}
                statesOptions={allStates}
                handleEdit={handleOpenUpdateGroupModal}
            />

        </Container>
    )
}

export default withAuth(withAxios(GroupsDashboard));
