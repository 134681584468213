import React from 'react';
import {styles} from '../../Stylesheet.css';
import {
    Container,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Paper
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Row = ({title, children}) => {

    const {messageGuidelinesRow, messageGuidelinesRowTitle, messageGuidelinesRowBody} = styles();

    return (
        <TableRow className={messageGuidelinesRow}>
            <TableCell
                expandIcon={<ExpandMoreIcon/>}>
                <Typography className={messageGuidelinesRowTitle}>{title}</Typography>
            </TableCell>
            <TableCell>
                <Typography className={messageGuidelinesRowBody}>{children}</Typography>
            </TableCell>
        </TableRow>
    );
}

const MessageGuidelinesPage = () => {
    const globalStyles = styles();

    return (
        <>
            <Container style={{paddingTop: "30px", paddingBottom: "30px"}}>
                <Typography className={globalStyles.messageGuidelinesHeader}>SMS Message Guidelines</Typography>
                <Typography className={globalStyles.messageGuidelinesHeaderSubtext}><b>Note to users:</b> please note
                    that you are ultimately accountable for responsible use of Staff Messaging Service. If you have
                    questions about the content, frequency, or purpose of this application, please discuss the matter
                    with your organizational leadership.</Typography>
                <Paper>
                    <TableContainer className={globalStyles.messageGuidelinesTable}>
                        <TableBody>
                            <Row title="What the messages should be">
                                <ul>
                                    <li>Healing and respectful</li>
                                    <li>Hopeful, positive and inspirational</li>
                                    <li>Appreciative and encouraging</li>
                                    <li>Aligned with our Mission and Values</li>
                                </ul>
                            </Row>
                            <Row title="Text Messages cannot be...">
                                <ul>
                                    <li>Protected Health Information (PHI) OR link to PHI</li>
                                    <li>Copyrighted or trademarked content that is not owned by Ascension (unless
                                        licensed)
                                    </li>
                                    <li>Sexually explicit material of any type</li>
                                    <li>Nudity</li>
                                    <li>Anything promoting alcohol, illegal drugs/tobacco/controlled substance abuse
                                    </li>
                                    <li>Profanity and hateful or discriminatory speech</li>
                                    <li>Violence and threats of violence</li>
                                    <li>Confidential information</li>
                                    <li>Anything involving misleading or confusing others via impersonation</li>
                                    <li>Anything promoting or involving dangerous, unlawful, or illegal activities</li>
                                </ul>
                            </Row>
                            <Row title="Messaging Authority">Application Admins, Area Admins and publishers assigned for
                                the various messaging groups will have the authority to send messages.</Row>
                            <Row title="Publishing Times">Messages can be set for immediate send out or scheduled for
                                later date and time.</Row>
                            <Row title="Set Up for Administrators">New administrators must be set up by the Staff
                                Messaging Service team. The application admin can also add more Market level admins and
                                publishers.</Row>
                            <Row title="Text Message Criteria">
                                Messages have a 1560 character maximum; messages over 160 characters may be broken up
                                into multiple text messages depending on recipient's mobile carrier
                                <br/><br/>
                                Messaging Types:
                                <ol>
                                    <li>Text</li>
                                    <li>Image</li>
                                    <li>Video Message via link</li>
                                    <li>Secured Message through link</li>
                                </ol>
                                <br/>
                                <u>Do's and Do Not's</u>
                                <br/>
                                <b>Do</b> - use common language, pay attention to frequency
                                <br/>
                                <b>Do not</b> - include PHI, include confidential info, use abbreviations unless certain
                                they are consistently used Ascension-wide
                            </Row>
                            <Row title="Messaging Links">
                                <ul>
                                    <li>Create short links from <a
                                        href="https://bitly.com/">https://bitly.com/</a> (paid version provides data
                                        tracking tools)
                                    </li>
                                    <li>Videos can be sent through link only (E.g. YouTube, Facebook, etc.)</li>
                                    <li>Ensure full link is included in the message (Hyperlinks embedded in text <b>DO
                                        NOT</b> transfer to text messages, i.e. <a
                                        href="mailto:nursingcoe@ascension.org">nursingcoe@ascension.org</a> or <a
                                        href="https://www.google.com">www.google.com</a>)
                                    </li>
                                </ul>
                            </Row>
                            <Row title="Messaging images">
                                Ensure images sent are about 1MB (Larger the image, there is risk the image may not be sent).<br/><br/>
                                The following image formats can be sent:
                                <ol>
                                    <li>JPEG</li>
                                    <li>PNG</li>
                                    <li>GIF</li>
                                </ol>
                            </Row>
                            <Row title="Promotional Material and Distribution">
                                Available in Brand Central
                                <ul>
                                    <li>8.5x11 Poster template</li>
                                    <li>Social Media template</li>
                                    <li>Email template</li>
                                </ul>
                                Distribution Recommentations
                                <ul>
                                    <li>Team Meetings/Huddle/DMS Boards</li>
                                    <li>Email</li>
                                    <li>Break Rooms</li>
                                    <li>Nurses Stations</li>
                                    <li>Social Media group specific to facility nurses</li>
                                    <li>Unit text messaging groups</li>
                                </ul>
                            </Row>
                            <Row title="Considerations for Message Content">
                                <ul>
                                    <li>Try to keep messages to approximately 160-300 word maximum for best results</li>
                                    <li>Connect, Engage, Inform by being clever and concise with your message</li>
                                    <li><em>Is it relevant? Does it resonate?</em> Remember your AUDIENCE</li>
                                    <li>Always double check and ask others to proofread before publishing</li>
                                </ul>
                            </Row>
                            <Row title="Duplicate Subscriptions">
                                A single message can be sent to multiple groups. If a member is subscribed to multiple
                                groups, Staff Messaging Service will ensure they only receive the message once.
                            </Row>
                            <Row title="Questions or Issues">
                                Should you have any questions or concerns please email to <a
                                href="mailto:StaffMessagingService@ascension.org">StaffMessagingService@ascension.org</a>
                            </Row>
                            <Row title="Non-secure Messages">
                                <ul>
                                    <li>Messages of gratitude</li>
                                    <li>Facility announcements (i.e. Nurses' week lunch served today!, facilities code
                                        response, etc.)
                                    </li>
                                    <li>Philanthropic Events (e.g. Heart Walk, Community Mission Events, etc.)</li>
                                    <li>Video messages</li>
                                </ul>
                            </Row>
                            <Row title="Secure Messages">
                                <ul>
                                    <li>DAISY Awardee with link to Patient Recommentation letter</li>
                                    <li>Any Awards specifying the associate's name, etc.</li>
                                    <li>Meaningful Patient Experience Feedback</li>
                                </ul>
                            </Row>
                        </TableBody>
                    </TableContainer>
                </Paper>
            </Container>
        </>
    );
}

export default MessageGuidelinesPage;