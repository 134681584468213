/* eslint-disable */

import React, {useState, useEffect, useRef} from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    FormLabel,
    FormControl,
    FormHelperText,
    TextField,
    Typography,
    LinearProgress,
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {styles} from "../../Stylesheet.css";
import MaterialTable from "material-table";
import {getEnvVars} from '../utils/env-utils';
import {withAuth} from "../../auth/AuthProvider";
import withAxios from '../common/withAxios';

var theEnv = getEnvVars(window.location.host);
const PHONE_NUMBER_TYPE_REGULAR = "REGULAR";
const GroupsModal = ({
                         axios,
                         isUpdateModal = false,
                         modalTitle = "Notification Groups Modal",
                         initialUsers = [],
                         availableStates = [],
                         isOpen = false,
                         isLoading = false,
                         isUserListLoading = false,
                         handleClose = () => {
                         },
                         handleSubmit = () => {
                         },
                         submitError = null,
                         initialGroupName = "",
                         initialAreaCode = "",
                         initialDescription = "",
                         initialState = "",
                         submitText = "Submit",
                     }) => {
    const globalStyles = styles();
    const usersTableRef = useRef()
    
    // FORM STATES
    const [groupName, setGroupName] = useState("");
    const [description, setDescription] = useState("");
    const [areaCodeText, setAreaCodeText] = useState([]);
    const [twilioNumber, setTwilioNumber] = useState("");
    const [usersInGroup, setUsersInGroup] = useState([]);
    const [allPageUsers, setAllPageUsers] = useState([]);
    const [state, setState] = useState("");

    const [availableUsers, setAvailableUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    // ERROR STATES
    const [groupNameError, setGroupNameError] = useState(null);
    const [descriptionError, setDescriptionError] = useState(null);
    const [areaCodeTextError, setAreaCodeTextError] = useState(null);
    const [twilioNumberError, setTwilioNumberError] = useState(null);
    const [usersInGroupError, setUsersInGroupError] = useState(null);
    const [stateSelectError, setStateSelectError] = useState(null);

    const [isAreaCodeLoading, setIsAreaCodeLoading] = useState(false);
    const [isTwilioNumberSelectOpen, setIsTwilioNumberSelectOpen] = useState(false);
    const [previousAreaCode, setPreviousAreaCode] = useState("");
    const [availableTwilioNumbers, setAvailableTwilioNumbers] = useState([]);

    // INIT STATE
    useEffect(() => {
        if (isOpen) {
            setGroupName(initialGroupName);
            setAreaCodeText(initialAreaCode);
            setDescription(initialDescription);
            setState(initialState);
            setAvailableTwilioNumbers([]);
            setUsersInGroup(initialUsers || []);
            setGroupNameError(null);
            setAreaCodeTextError(null);
            setTwilioNumberError(null);
            setDescriptionError(null);
            setUsersInGroupError(null);
            setStateSelectError(null);
        }
    }, [isOpen]);

    useEffect(() => {
        if (usersTableRef.current) usersTableRef.current.onQueryChange();
    }, [state]);

    // STATE FUNCTIONS
    const handleChangeSelectState = (event, value, reason) => {
        if (stateSelectError !== null) setStateSelectError(null);
        setState(value);
    }

    // GROUP NAME FUNCTIONS
    const handleChangeGroupName = e => {
        if (!!groupNameError) setGroupNameError(null);
        setGroupName(e.target.value);
    }

    // AREA CODE FUNCTIONS
    const fetchTwilioNumbers = areaCode => axios.get(theEnv.REACT_APP_TWILIO_AREA_CODE_API, {
        params: {areaCode}
    })

    const handleChangeAreaCodeText = ({target: {value}}) => {
        if (!!areaCodeTextError) setAreaCodeTextError(null);
        setAreaCodeText(value);

    }

    // GROUPS FUNCTIONS
    const handleChangeUsers = rows => {
        let allSelectedUsers = [...usersInGroup];
        const pageIds = allPageUsers.map(user => user.userId);
        const selectedPageIds = rows.map(user => user.userId);
        pageIds.forEach(id => {
            if (allSelectedUsers.includes(id) && !selectedPageIds.includes(id)) {
                const index = allSelectedUsers.findIndex(i => i === id);
                allSelectedUsers.splice(index, 1);
            } else if (!allSelectedUsers.includes(id) && selectedPageIds.includes(id)) {
                allSelectedUsers.push(id);
            }
        });
        setUsersInGroup(allSelectedUsers);
    }

    useEffect(() => {
        if (areaCodeText.length === 3 && areaCodeText !== previousAreaCode) {
            setIsAreaCodeLoading(true);
            fetchTwilioNumbers(areaCodeText)
                .then(({data}) => {
                    if (data && data.length > 0) {
                        setAvailableTwilioNumbers(data);
                        setIsTwilioNumberSelectOpen(true);
                    } else setAreaCodeTextError("Unable to find any numbers for that area code")
                    setPreviousAreaCode(areaCodeText);
                })
                .catch(e => console.error(e))
                .finally(() => setIsAreaCodeLoading(false));
        }
    }, [areaCodeText])

    // USERS FUNCTIONS
    const handleChangeUserSelection = rows => {
        setUsersInGroup(rows.map(r => r.userId));
    }

    // TWILIO NUMBER FUNCTIONS
    const handleChangeTwilioNumber = e => {
        if (!!twilioNumberError) setTwilioNumberError(null);
        setTwilioNumber(e.target.value);
    }

    // DESCRIPTION FUNCTIONS
    const handleChangeDescription = e => {
        if (!!descriptionError) setDescriptionError(null);
        setDescription(e.target.value);
    }

    // VALIDATE AND SUBMIT
    const validateAndSubmit = () => {
        let valid = true;

        // VALIDATE STATE
        if (!state || state.trim() === "") {
            valid = false;
            setStateSelectError("Please select a state");
        } else if (!availableStates.includes(state)) {
            valid = false;
            setStateSelectError("Please select a valid state from the dropdown menu");
        }
        if(stateSelectError === "User not assigned to selected State"){
            valid =false
        }

        // VALIDATE GROUP NAME
        if (!groupName || groupName.trim() === "") {
            valid = false;
            setGroupNameError("Please enter a valid group name");
        }

        if (!isUpdateModal) {
            // VALIDATE AREA CODE
            if (!areaCodeText || areaCodeText.trim() === "") {
                valid = false;
                setAreaCodeTextError("Please enter a valid area code");
            }
            // VALIDATE TWILIO NUMBER
            if (!twilioNumber || twilioNumber.trim() === "") {
                valid = false;
                setTwilioNumberError("Please select a twilio number");
            } else if (!availableTwilioNumbers.includes(twilioNumber.trim())) {
                valid = false;
                setTwilioNumberError("Please select one of the provided twilio number");
            }
        }

        // VALIDATE DESCRIPTION
        if (!description || description.trim() === "") {
            valid = false;
            setDescriptionError("Please enter a valid description");
        }

        if (valid) {
            if (isUpdateModal) handleSubmit({
                groupName: groupName.trim(),
                description: description.trim(),
                state: state.trim(),
                usersInGroup
            })
            else handleSubmit({
                groupName: groupName.trim(),
                description: description.trim(),
                state: state.trim(),
                twilioNumbers: [{
                    number: twilioNumber.trim(),
                    type: PHONE_NUMBER_TYPE_REGULAR
                }],
                usersInGroup
            })
        }
    }

    return (
        <Dialog open={isOpen} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
            <DialogContent>
                <Typography> </Typography>
                <FormControl
                    fullWidth
                    required
                    error={!!stateSelectError}
                >
                    <FormLabel>State</FormLabel>
                    <Autocomplete
                        fullWidth
                        onChange={handleChangeSelectState}
                        value={state}
                        id="stateSelect"
                        options={availableStates}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField
                                variant="outlined"
                                fullWidth
                                {...params}
                            />)}
                    />
                    {!!stateSelectError && <FormHelperText>{stateSelectError}</FormHelperText>}
                </FormControl>
                <FormControl
                    style={{margin: "20px 0"}}
                    fullWidth
                    variant="outlined"
                    error={!!groupNameError}
                    required>
                    <FormLabel>Group Name</FormLabel>
                    <TextField
                        variant="outlined"
                        id="groupName"
                        placeholder="State - Role - Facility Name - City"
                        value={groupName}
                        onChange={handleChangeGroupName}
                        fullWidth
                        multiline
                    />
                    <FormHelperText>Ex: OK - Nurse - St John Owasso, Owasso</FormHelperText>
                    {!!groupNameError && <FormHelperText>{groupNameError}</FormHelperText>}
                </FormControl>
                <FormControl
                    style={{margin: "20px 0"}}
                    fullWidth
                    variant="outlined"
                    error={!!descriptionError}
                    required>
                    <FormLabel>Description</FormLabel>
                    <TextField
                        variant="outlined"
                        id="description"
                        value={description}
                        onChange={handleChangeDescription}
                        fullWidth
                        multiline
                        rows={3}
                    />
                    {!!descriptionError && <FormHelperText>{descriptionError}</FormHelperText>}
                </FormControl>

                {!isUpdateModal &&
                <FormControl
                    style={{margin: "20px 0"}}
                    fullWidth
                    variant="outlined"
                    error={!!areaCodeTextError}
                >
                    <FormLabel>Desired Area Code</FormLabel>
                    <TextField
                        disabled={isAreaCodeLoading}
                        variant="outlined"
                        id="areaCodeText"
                        placeholder="Type Area Code"
                        value={areaCodeText}
                        onChange={handleChangeAreaCodeText}
                        fullWidth
                        multiline
                    />
                    {isAreaCodeLoading && <LinearProgress/>}
                    {!!areaCodeTextError && <FormHelperText>{areaCodeTextError}</FormHelperText>}
                </FormControl>}

                {(!isUpdateModal && (!isAreaCodeLoading && availableTwilioNumbers.length > 0)) &&
                <FormControl
                    style={{margin: "20px 0"}}
                    fullWidth
                    variant="outlined"
                    error={!!twilioNumberError}
                    required>
                    <FormLabel>Choose Twilio Number</FormLabel>
                    <Select
                        autoFocus
                        variant="outlined"
                        id="twilioNumber"
                        placeholder="Choose Twilio Number"
                        value={twilioNumber}
                        onChange={handleChangeTwilioNumber}
                        fullWidth
                        open={isTwilioNumberSelectOpen}
                        onOpen={() => setIsTwilioNumberSelectOpen(true)}
                        onClose={() => setIsTwilioNumberSelectOpen(false)}
                    >
                        {availableTwilioNumbers.map((number, index) => (
                            <MenuItem key={`twilio-number-select-${number}`} value={number}>{number}</MenuItem>
                        ))}
                    </Select>
                    {!!twilioNumberError && <FormHelperText>{twilioNumberError}</FormHelperText>}
                </FormControl>}
                {(state !== "") && <FormControl fullWidth>
                    <MaterialTable
                        title=""
                        tableRef={usersTableRef}
                        data={query => new Promise((resolve, reject) => {
                            axios.post(theEnv.REACT_APP_USERS_BY_STATES_API_URI, {
                                states: [state],
                                pageNumber: query.page,
                                pageSize: query.pageSize,
                                searchString: query.search,
                                sort: [{
                                    orderBy: (query.orderBy && query.orderBy.field) || "email",
                                    orderDirection: query.orderDirection || "asc"
                                }]
                            })
                                .then(({data}) => {
                                    setAllPageUsers(data.content);
                                    let content = data.content.map(item => {
                                        if (usersInGroup.length && usersInGroup.includes(item.userId)) {
                                            return { ...item, tableData: { checked: true } }
                                        } else return item;
                                    });
                                    resolve({
                                        data: content,
                                        page: query.page,
                                        totalCount: data.totalElements,
                                    })
                                })
                                .catch(e => {
                                    console.error(e);
                                    if(e.response.data === "User not assigned to selected State") {
                                        setStateSelectError("User not assigned to selected State")

                                    }
                                    resolve({
                                        data: [],
                                        page: query.page,
                                        totalCount: 0,
                                    })
                                });
                        })}
                        localization={{
                            body: {
                                emptyDataSourceMessage: (
                                    <Typography className={globalStyles.noRecords}>
                                        No records available.
                                    </Typography>
                                ),
                            },
                        }}
                        columns={[
                            {
                                field: 'email', title: 'User Email',
                            },
                        ]}
                        options={{
                            selection: true,
                            showTextRowsSelected: false
                        }}
                        onSelectionChange={handleChangeUsers}
                    />
                </FormControl>}
                {submitError &&
                <FormHelperText error>
                    {submitError}
                </FormHelperText>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading}>
                    Cancel
                </Button>
                <Button onClick={validateAndSubmit}
                        disabled={isLoading}
                        className={globalStyles.hello}
                        variant="contained" color="secondary">
                    {submitText}
                </Button>
            </DialogActions>
            {isLoading && <LinearProgress/>}
        </Dialog>
    )
}

export default withAuth(withAxios(GroupsModal));