import React from 'react';
import SubscriberReports from './SubscriberReports/SubscriberReportsController';
import MessageDeliveryTable from "./MessageDeliveryReports";
import {getEnvVars} from '../utils/env-utils';
import GroupCreationReports from "./GroupCreationReports/GroupCreationReportsController";

var theEnv = getEnvVars(window.location.host);
const reportTypes = {
    SUBSCRIBERS: {
        value: "SUBSCRIBERS",
        label: "Subscriber Report",
        endpoint: theEnv.REACT_APP_REPORTS_API,
        renderReportComponent: props => <SubscriberReports {...props} />,
    },
    DELIVERY_STATUS: {
        value: "DELIVERY_STATUS",
        label: "Message Delivery Report",
        endpoint: theEnv.REACT_APP_REPORTS_API,
        renderReportComponent: props => <MessageDeliveryTable {...props} />,
    },
    GROUP_CREATION: {
        value: "GROUP_CREATION",
        label: "Group Creation Report",
        endpoint: theEnv.REACT_APP_REPORTS_API,
        renderReportComponent: props => <GroupCreationReports {...props} />,
    },
}

export default reportTypes;