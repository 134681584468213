import { createTheme } from '@material-ui/core/styles';

const colors = {
    darkBlue: {
        light: '#4b5ca2',
        main: '#143373',
        dark: '#000d47',
        contrastText: '#fff'
    },
    purple: {
        light: '#dd76ae',
        main: '#a9467f',
        dark: '#771253',
        contrastText: '#fff'
    },
    green: '#01A890',
    black: '#000',
    gray: '#757575',
    white: '#fff'
};

export default createTheme({
    palette: {
        type: 'light',
        primary: {...colors.darkBlue},
        secondary: {...colors.purple},
        text: {
            primary: colors.black,
            secondary: colors.gray
        },

    },
    overrides: {}
});
