import {UserAgentApplication} from 'msal';
import {getEnvVars} from "../components/utils/env-utils";

var envVars = getEnvVars(window.location.host);
const applicationId = envVars.REACT_APP_APPLICATION_ID;

export const config = {
    instance: envVars.REACT_APP_INSTANCE,
    tenant: envVars.REACT_APP_TENANT,
    signInPolicy: envVars.REACT_APP_SIGN_IN_POLICY,
    editProfilePolicy: envVars.REACT_APP_EDIT_PROFILE_POLICY,
    applicationId,
    cacheLocation: 'localStorage',
    scopes: envVars.REACT_APP_SCOPE,
    redirectUri: envVars.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: `${window.location.origin}/`
};

export const initMsalApp = () => {
    let msalApp;
    try {
        msalApp = new UserAgentApplication({
            auth: {
                clientId: config.applicationId,
                authority: `${config.instance}${config.tenant}/${config.signInPolicy}`,
                scopes: config.scopes,
                redirectUri: config.redirectUri,
                postLogoutRedirectUri: config.postLogoutRedirectUri,
                validateAuthority: false,
                navigateToLoginRequestUrl: false
            },
            cache: {cacheLocation: config.cacheLocation, storeAuthStateInCookie: true},
        });
    } catch (e) {
        if (e.message.indexOf("Hash does not contain state") > -1) {
            window.location.href = window.location.origin + "?loginError=true";
        }
    }
    return msalApp;
};