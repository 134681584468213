/* eslint-disable */
import React, { useState, useRef } from 'react';
import { styles } from '../../../Stylesheet.css';
import {Paper, Typography} from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from "moment";

const GroupCreationDetails = ({tableData = {}}) => {
    const globalStyles = styles();

    return (
        <Paper style={{margin: "20px 0"}}>
            <MaterialTable
                title=""
                data={tableData.groupList}
                options={{
                    draggable: false,
                    toolbar: false,
                    search: false,
                    maxBodyHeight: '40vh',
                    headerStyle: {
                        background: "linear-gradient(to right, #1b4297, #8490d9)",
                        font: "Whitney, sans-serif",
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#ffffff",
                    },
                    pageSize: 5,
                    pageSizeOptions: [1, 5, 10, 20]
                }}
                columns={[

                    { field: "groupName", title: "Group Name" },
                    { field: "createdBy", title: "Group creator"},
                    { field: "twilioNumber", title: "Twilio Number"},
                    { field: "state", title: "State"},
                    { field: "createdOn", title: "Date Created (UTC)",
                    render: rowData => {
                        return moment(rowData.createdOn).format('MM-DD-YYYY HH:mm:ss');
                    }},



                ]}
            />
        </Paper>
    );
};

export default GroupCreationDetails;