import React from 'react';
import {Link, withRouter} from 'react-router-dom'
import {styles} from '../../Stylesheet.css';
import {AppBar, Toolbar, Typography, Container, Button} from '@material-ui/core';
import logo from '../../Assets/AscensionLogoHeader.svg';
import {withAuth} from '../../auth/AuthProvider';


const Header = ({auth}) => {
    const globalStyles = styles();

    return (
        <AppBar
            position="relative"
            color="inherit"
            elevation={2}
            data-test="header-component">
            <Container>
                <Toolbar align="center" className={globalStyles.header}>
                    <img src={logo} alt="logo" className={globalStyles.headerLogo}/>

                    <Typography
                        variant="h5"
                        color="primary"
                        className={globalStyles.headerText}>
                        Staff Messaging Service

                        <Typography className={globalStyles.headerLinks}><Link to="/profileinfo" className={globalStyles.NameLink}>
                            {auth.account.name}
                        </Link>
                        </Typography>
                            <Link className={globalStyles.headerLinks} onClick={auth.logout}>
                            <Button className={globalStyles.headerLinks}>log out</Button>
                        </Link>

                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default withRouter(withAuth(Header))