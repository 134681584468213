import {makeStyles} from '@material-ui/core/styles';
import healthcare from "./Assets/healthcare2.jpg"

const styles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
        backgroundColor: "#F3F4F8",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
    headerLogo: {
        margin: "10px 0",
        maxHeight: "64px",
    },
    footer: {
        width: "100%",
        textAlign: "center",
        backgroundColor: "#1A4297",
        position: "fixed",
        bottom: 0,
    },
    footerLogo: {
        maxHeight: "48px",
    },
    dashboardContainer: {
        padding: "30px 0",
    },
    navigation: {
        flexGrow: 1,
        justify: "center",
    },
    links: {
        textDecoration: "unset",
        color: "#757575",
        paddingLeft: "20px",
        paddingRight: "25px",
        paddingBottom: "7px",
        fontSize: "0.875rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        "&:hover": {
            borderBottom: "3px solid #1E69D2",
            outline: "none",
            textDecoration: "none"
        },
    },
    links1: {
        margin: "auto",
    },
    appheader: {
        position: "absolute !important",
        marginTop: "105px !important",
        padding: "13px !important",
        backgroundColor: "#F9F9F9 !important",
        maxWidth: "100% !important",
        boxShadow: "0px 0px 0px 0px !important",

    },
    headerText: {
        color: "#1A4297",
        fontWeight: "bold",
        marginTop: "15px !important",
    },
    headerSubtext: {
        textTransform: "lowercase",
        fontWeight: "normal",
        fontSize: "20px",
    },
    headerLinks: {
        color: "#1A4297",
        fontSize: "24px",
        textTransform: "capitalize !important",
    },
    buttton: {
        marginTop: "55px",
        backgroundColor: "#1B4297",
        color: "white",
        width: "241px",
        borderRadius: "24px",
    },
    createButtton:{
        backgroundColor: "#01A890 !important",
        marginTop: "55px !important",
        width: "221px",
    },
    hello: {
        color: "white",
        backgroundColor: "#00A890"
    },
    messagingGroupsAll: {
        height: "66px",
        width: "959px",
        color: "#222222",
        fontFamily: "Chronicle Text G1, serif",
        fontSize: "36px !important",
        fontWeight: "600",
        letterSpacing: "1.5px",
        lineHeight: "56px",
        paddingTop: "47px",
    },
    pageHeaders:{
        height: "46px",
        width: "959px",
        color: "#222222",
        fontFamily: "Chronicle Text G1, serif",
        fontSize: "36px !important",
        fontWeight: "600",
        letterSpacing: "1.5px",
        lineHeight: "56px",
        paddingTop: "47px",
    },
    tableTitle: {
        height: "66px",
        width: "959px",
        color: "#222222",
        fontFamily: "Chronicle Text G1, serif",
        fontSize: "36px !important",
        fontWeight: "600",
        letterSpacing: "1.5px",
        lineHeight: "56px",
        paddingTop: "47px"
    },
    tableTitles:{
        borderTop: "3px solid #B40187"
    },
    backToDashboard: {
        height: "20px",
        width: "100%",
        color: "#1E69D2",
        fontFamily: "Whitney, sans-serif",
        fontSize: "18px",
        fontWeight: "500",
        letterSpacing: 0,
        lineHeight: "20px",
        marginTop: "25px",
    },
    backIcon: {
        color: "#00A791",
        height: "11px",
        width: "12px"
    },
    backToDashboardLink: {
        color: "#1E69D2",
        textDecoration: "none",
    },
    sendMessageInner: {
        padding: "28px 80px",
        borderTop: "3px solid #b40f87"
    },
    sendMessageSectionHeader: {
        color: "#2B2B2B",
        fontFamily: "Chronicle Text G1, serif",
        fontSize: "36px",
        fontWeight: "600",
        lineHeight: "38px",
    },
    sendMessageTotalRecipients: {
        color: "#01A890",
        fontFamily: "Whitney, sans-serif",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "16px",
    },
    sendMessageChips: {
        padding: "10px 0",
    },
    sendMessageChip: {
        marginRight: "10px",
    },
    sendMessageCheckBoxes: {
        backgroundColor: "white",
        padding: "12px"
    },
    sendMessageGuidelines: {
        backgroundColor: "rgba(30, 105, 210, 0.25)",
        margin: "16px 0"
    },
    sendMessageGuidelinesHeader: {
        fontFamily: "Whitney, sans-serif",
        fontSize: "18px",
        lineHeight: "21px",
        color: "#000000",
    },
    sendMessageGuidelinesText: {
        fontFamily: "Whitney, sans-serif",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#000000",
    },
    sendMessageGuidelinesTextBullets: {
        paddingBottom: "8px",
    },
    sendMessageCharacterCount: {
        fontFamily: "Whitney, sans-serif",
        color: "#000000",
        lineHeight: "16px",
        fontSize: "14px",
        fontStyle: "italic",
    },
    sendMessageAddImageIcon: {
        transform: "rotate(45deg)",
    },
    sendMessageAddImageText: {
        color: "#1B4297",
        fontFamily: "Whitney, sans-serif",
        fontSize: "18px",
        lineHeight: "21px",
        cursor: "pointer",
        '&:hover': {
            textDecoration: "underline"
        }
    },
    sendMessageAddImageUnavailable: {
        fontFamily: "Whitney, sans-serif",
        fontSize: "18px",
        lineHeight: "21px",
    },
    messagesListGroupNameText: {
        fontSize: "12px",
        lineHeigh: "12px",
        fontFamily: "Whitney, sans-serif"
    },
    columnSize: {
        maxWidth: "100px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    pageHeader: {
        color: "#222222",
        fontFamily: "Chronicle Text G1, serif",
        fontSize: "36px !important",
        fontWeight: "600",
        letterSpacing: "1.5px",
        lineHeight: "56px",
        paddingTop: "47px",
    },
    tableHeader: {
        color: "#222222",
        fontFamily: "Chronicle Text G1, serif",
        fontSize: "20px !important",
        fontWeight: "600",
        // backgroundImage: `url(${medicalAscension})`,
    },
    tableinfo: {
        color: "red",
        fontFamily: "Chronicle Text G1, serif",
        fontSize: "12px !important",
        fontWeight: "600",
        marginLeft: "24px !important",
        fontStyle: "italic",
        paddingBottom: "20px"
    },
    tableHeaders: {
        borderTop: "1px solid #B40187",
        height: "100%",
        backgroundImage: `url(${healthcare})`,
    },

    reportTableHeader: {
        color: "#222222",
        fontFamily: "Chronicle Text G1, serif",
        fontSize: "24px",
        fontWeight: "600",
        margin: "30px 0 15px 0"
    },
    reportHeaderCell: {
        background: "linear-gradient(to right, #1b4297, #8490d9)",
        font: "Whitney, sans-serif",
        fontSize: "12px",
        fontWeight: "600",
        color: "#ffffff !important",
        minWidth: "140px"
    },
    reportTotalCell: {
        background: "linear-gradient(to right, #999999, rgba(153, 153, 153, 0.55))",
        font: "Whitney, sans-serif",
        fontSize: "14px",
        fontWeight: "400",
        color: "#ffffff !important",
    },
    reportStateCell: {
        font: "Whitney, sans-serif",
        fontSize: "14px",
        fontWeight: "600",
        color: "#000000 !important",
    },
    reportTableCell: {
        font: "Whitney, sans-serif",
        fontSize: "12px",
        fontWeight: "400",
        color: "#000000 !important",
    },
    reportStateCell1: {
        color: "red"
    },
    messageGuidelinesLink: {
        float: "right",
        color: "#1E69D2",
        fontFamily: "Whitney, sans-serif",
        fontSize: "14px",
        fontWeight: "300",
        cursor: "pointer",
    },
    messageGuidelinesHeader: {
        color: "#222222",
        fontFamily: "Chronicle Text G1, serif",
        fontSize: "36px !important",
        fontWeight: "600",
        paddingTop: "47px"
    },
    messageGuidelinesHeaderSubtext: {
        color: "#222222",
        fontFamily: "Whitney, sans-serif",
        fontSize: "16px",
        fontWeight: "normal",
        margin: "20px 0 !important"
    },
    messageGuidelinesTable: {
        marginBottom: "50px"
    },
    messageGuidelinesRow: {
        margin: "16px 0",
        borderRadius: "4px",
    },
    messageGuidelinesRowTitle: {
        fontSize: "18px",
        fontWeight: "600",
        fontFamily: "Whitney, sans-serif",
        padding: "10px 0",
    },
    messageGuidelinesRowBody: {
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "Whitney, sans-serif",
        padding: "10px",
    },
    imageHelperText: {
        fontSize: "12px !important",
        color: "#1E69D2",
        fontStyle: "italic"
    },
    helpertext:{
        fontSize: "13px !important",
        fontStyle: "italic",
    },
    warningText:{
        color: "red",
        fontFamily: "Chronicle Text G1, serif",
        fontSize: "14px !important",
        fontWeight: "600",
        paddingBottom: "20px"
    },
    noRecords: {
        color: "red !important",
        fontFamily: "Chronicle Text G1, serif",
        fontSize: "16px !important",
        fontWeight: "600",
        paddingBottom: "20px"
    },
    infoText: {
        fontSize: "12px",
        fontFamily: "Chronicle Text G1, serif",
        color: "#000000"
    },
    NameLink: {
        color: "#1A4297",
        fontSize: "16px",
        textTransform: "capitalize !important",
    }
}))

export {styles}
