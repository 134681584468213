/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {styles} from '../../Stylesheet.css';
import {Container, Typography, Grid, Button, CircularProgress} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/SaveAlt';
import Navbar from '../common/Navbar';
import ReportsFilterForm from './ReportsFilterForm';
import ReportTypeForm from './ReportTypeForm';
import reportTypes from './ReportTypes';
import {getEnvVars} from '../utils/env-utils';
import {withAuth} from "../../auth/AuthProvider";
import withAxios from '../common/withAxios';

var theEnv = getEnvVars(window.location.host);
const ReportsDashboardController = ({axios}) => {
    const globalStyles = styles();
    
    const [states, setStates] = useState([]);
    const [isReportTypeExpanded, setIsReportTypeExpanded] = useState(true);
    const [reportType, setReportType] = useState({});
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(false);
    const [tableFilters, setTableFilters] = useState({});
    const [tableData, setTableData] = useState([]);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [isReportExporting, setIsReportExporting] = useState(false);
    const [showReport, setShowReport] = useState(false);

    // Set report type
    const handleChangeReportType = type => {
        setReportType(reportTypes[type]);
        setTableFilters({});
        setTableData([]);
        setShowReport(false);
        setIsFiltersExpanded(true);
    }

    // Set filters and send API request
    const handleSubmitFilters = (filters, showTotalSubscriberReport, showSubscriberCompleteDetails, showTotalGroupsReport, showGroupCreationDetails) => {
        setTableFilters(filters);
        setTableData([]);
        setShowReport(false);

        let filterData = {...filters}
        setIsDataFetching(true);
        axios.post(reportType.endpoint, filterData)
            .then(res => {
                let data = res.data;
                if (reportType === reportTypes.SUBSCRIBERS) data = {
                    data,
                    showTotalSubscriberReport,
                    showSubscriberCompleteDetails,
                    filterData
                }
                if (reportType === reportTypes.GROUP_CREATION) data = {
                    data,
                    showTotalGroupsReport,
                    showGroupCreationDetails,
                    filterData
                }
                setTableData(data);
                setIsFiltersExpanded(false);
                setShowReport(true);
            })
            .catch(e => console.error(e))
            .finally(() => setIsDataFetching(false));

    }

    // Request CSV data
    const handleExportCSV = () => {
        setIsReportExporting(true);
        axios.post(theEnv.REACT_APP_REPORT_CSV_EXPORT_URI, tableFilters, {responseType: 'blob'})
            .then((res) => {
                const filename = `${reportType.label.replace(/\s/g, '-')}-${Date.now()}.zip`;

                const blob = new Blob([res.data], {type: 'application/zip'});
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.click();
            })
            .catch(e => console.error(e))
            .finally(() => setIsReportExporting(false));
    }

    // Get States for state filter dropdown
    useEffect(() => {
        axios.get(theEnv.REACT_APP_GET_STATES_API)
            .then(({data}) => setStates(data))
            .catch(e => console.error(e));
    }, []);

    // Close Filters if Report Types expanded
    useEffect(() => {
        if (isReportTypeExpanded) {
            setIsFiltersExpanded(false);
        }
    }, [isReportTypeExpanded]);

    // Close Report Types if Filters is expanded
    useEffect(() => {
        if (isFiltersExpanded) {
            setIsReportTypeExpanded(false);
        }
    }, [isFiltersExpanded]);

    return (
        <Container style={{paddingTop: "30px", paddingBottom: "30px", paddingLeft: "0px", paddingRight: "0px"}}>
            <Navbar/>
            <Typography className={globalStyles.pageHeader}>Reports</Typography>
            <ReportTypeForm
                isExpanded={isReportTypeExpanded}
                toggleExpanded={() => setIsReportTypeExpanded(!isReportTypeExpanded)}
                handleChange={handleChangeReportType}
            />
            {Object.entries(reportType).length > 0 &&
            <ReportsFilterForm
                availableStates={states}
                isExpanded={isFiltersExpanded}
                reportType={reportType.value}
                toggleExpanded={() => setIsFiltersExpanded(!isFiltersExpanded)}
                handleSubmit={handleSubmitFilters}
                isLoading={isDataFetching}
            />}
            {showReport &&
            <>
                <Grid container direction="row" justify="space-between" alignItems="flex-end">
                    <Grid item>
                        <Typography className={globalStyles.pageHeader}>{reportType.label}</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            size="large"
                            disabled={isReportExporting}
                            startIcon={isReportExporting ? <CircularProgress size={24} thickness={5} color="primary"/> :
                                <SaveIcon/>}
                            onClick={handleExportCSV}
                        >
                            Export Reports to CSV
                        </Button>
                    </Grid>
                </Grid>
                {reportType.renderReportComponent({tableData})}
            </>
            }
        </Container>
    )
}

export default withAuth(withAxios(ReportsDashboardController));