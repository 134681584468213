/* eslint-disable no-console */
export const envVars = {
  local: {
    REACT_APP_INSTANCE: "https://ascensionidqa.b2clogin.com/",
    REACT_APP_TENANT: "ascensionidqa.onmicrosoft.com",
    REACT_APP_SIGN_IN_POLICY: "B2C_1A_signup_signin",
    REACT_APP_EDIT_PROFILE_POLICY: "B2C_1A_profile_edit",
    REACT_APP_FORGOT_PASSWORD_POLICY: "B2C_1A_Password_Reset",
    REACT_APP_APPLICATION_ID: "33bb4c98-7fee-4a30-bbee-479c5e46ea24",
    REACT_APP_SCOPE: [
      "https://ascensionidqa.onmicrosoft.com/associate-messaging-service-api/app.access",
    ],
    REACT_APP_API_KEY_URI: "AnQeo6d45VjAxmQpCsvqLX0amyRYGuAn",
    REACT_APP_REDIRECT_URI: "http://localhost:3000/sendmessage",
    REACT_APP_NOTIFICATION_GET_GROUPS_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/group",
    REACT_APP_NOTIFICATION_POST_NEW_GROUP_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/group/create",
    REACT_APP_NOTIFICATION_POST_UPDATE_GROUP_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/group/update",
    REACT_APP_NOTIFICATION_GROUP_SUBSCRIBERS_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/subscribers",
    REACT_APP_NOTIFICATION_BROADCAST_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/b/broadcast",
    REACT_APP_MESSAGE_BROADCAST_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/readall",
    REACT_APP_NOTIFICATION_AUTHENTICATE_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/authenticate",
    REACT_APP_TOTAL_SUBSCRIBERS_REPORT_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/report/subscriber-complete-details",
    REACT_APP_DELIVERY_STATUS_REPORT_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/twilio/smsmessagereport",
    REACT_APP_ALL_USERS_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user/usersByStates",
    REACT_APP_CREATE_USERS_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user/createorupdate",
    REACT_APP_UPDATE_USERS_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user/updaterole",
    REACT_APP_TWILIO_AREA_CODE_API:
      "https://dev-api.ascension.org/sms/notification/internal/sms/group/phonenumbers",
    REACT_APP_GET_SCHEDULED_MESSAGES_API:
      "https://dev-api.ascension.org/sms/notification/internal/scheduledMessage/all",
    REACT_APP_GET_MESSAGES_HISTORY_API:
      "https://dev-api.ascension.org/sms/notification/internal/sms/messageHistory",
    REACT_APP_GET_STATES_API:
      "https://dev-api.ascension.org/sms/notification/internal/sms/group/states",
    REACT_APP_GET_GROUPS_BY_STATE_API:
      "https://dev-api.ascension.org/sms/notification/internal/user/findGroupsByState",
    REACT_APP_REPORTS_API:
      "https://dev-api.ascension.org/sms/notification/internal/report/filter-report",
    REACT_APP_SUBSCRIBER_DETAILS_REPORT_API:
      "https://dev-api.ascension.org/sms/notification/internal/sms/report/subscriber-complete-details",
    REACT_APP_DELETE_USER_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user/deleteRole",
    REACT_APP_ADD_IMAGE_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/sendMMS",
    REACT_APP_REPORT_CSV_EXPORT_URI:
      "https://dev-api.ascension.org/sms/notification/internal/report/csv/message-delivery",
    REACT_APP_GROUPS_FOR_MESSAGE_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/group/groupsForMessage",
    REACT_APP_ALL_USERS_OLD_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user",
    REACT_APP_USERS_BY_STATES_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user/usersByStates",
    REACT_APP_GROUPS_BY_STATES_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user/findGroupsByStates",
    REACT_APP_DELETE_SCHEDULED_MESSAGES_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/scheduledMessage/delete",
  },
  dev: {
    REACT_APP_INSTANCE: "https://ascensionidqa.b2clogin.com/",
    REACT_APP_TENANT: "ascensionidqa.onmicrosoft.com",
    REACT_APP_SIGN_IN_POLICY: "B2C_1A_signup_signin",
    REACT_APP_EDIT_PROFILE_POLICY: "B2C_1A_profile_edit",
    REACT_APP_FORGOT_PASSWORD_POLICY: "B2C_1A_Password_Reset",
    REACT_APP_APPLICATION_ID: "33bb4c98-7fee-4a30-bbee-479c5e46ea24",
    REACT_APP_SCOPE: [
      "https://ascensionidqa.onmicrosoft.com/associate-messaging-service-api/app.access",
    ],
    REACT_APP_API_KEY_URI: "AnQeo6d45VjAxmQpCsvqLX0amyRYGuAn",
    REACT_APP_REDIRECT_URI:
      "https://staff-messaging-service-web-dev.app.cloud-02.pcf.ascension.org/sendmessage",
    REACT_APP_NOTIFICATION_GET_GROUPS_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/group",
    REACT_APP_NOTIFICATION_POST_NEW_GROUP_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/group/create",
    REACT_APP_NOTIFICATION_POST_UPDATE_GROUP_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/group/update",
    REACT_APP_NOTIFICATION_GROUP_SUBSCRIBERS_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/subscribers",
    REACT_APP_NOTIFICATION_BROADCAST_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/b/broadcast",
    REACT_APP_MESSAGE_BROADCAST_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/readall",
    REACT_APP_NOTIFICATION_AUTHENTICATE_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/authenticate",
    REACT_APP_TOTAL_SUBSCRIBERS_REPORT_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/report/subscriber-complete-details",
    REACT_APP_DELIVERY_STATUS_REPORT_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/twilio/smsmessagereport",
    REACT_APP_ALL_USERS_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user/usersByStates",
    REACT_APP_CREATE_USERS_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user/createorupdate",
    REACT_APP_UPDATE_USERS_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user/updaterole",
    REACT_APP_TWILIO_AREA_CODE_API:
      "https://dev-api.ascension.org/sms/notification/internal/sms/group/phonenumbers",
    REACT_APP_GET_SCHEDULED_MESSAGES_API:
      "https://dev-api.ascension.org/sms/notification/internal/scheduledMessage/all",
    REACT_APP_GET_MESSAGES_HISTORY_API:
      "https://dev-api.ascension.org/sms/notification/internal/sms/messageHistory",
    REACT_APP_GET_STATES_API:
      "https://dev-api.ascension.org/sms/notification/internal/sms/group/states",
    REACT_APP_GET_GROUPS_BY_STATE_API:
      "https://dev-api.ascension.org/sms/notification/internal/user/findGroupsByState",
    REACT_APP_REPORTS_API:
      "https://dev-api.ascension.org/sms/notification/internal/report/filter-report",
    REACT_APP_SUBSCRIBER_DETAILS_REPORT_API:
      "https://dev-api.ascension.org/sms/notification/internal/sms/report/subscriber-complete-details",
    REACT_APP_DELETE_USER_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user/deleteRole",
    REACT_APP_ADD_IMAGE_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/sendMMS",
    REACT_APP_REPORT_CSV_EXPORT_URI:
      "https://dev-api.ascension.org/sms/notification/internal/report/csv/message-delivery",
    REACT_APP_GROUPS_FOR_MESSAGE_URI:
      "https://dev-api.ascension.org/sms/notification/internal/sms/group/groupsForMessage",
    REACT_APP_ALL_USERS_OLD_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user",
    REACT_APP_USERS_BY_STATES_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user/usersByStates",
    REACT_APP_GROUPS_BY_STATES_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/user/findGroupsByStates",
    REACT_APP_DELETE_SCHEDULED_MESSAGES_API_URI:
      "https://dev-api.ascension.org/sms/notification/internal/scheduledMessage/delete",
  },
  qa: {
    REACT_APP_INSTANCE: "https://ascensionidqa.b2clogin.com/",
    REACT_APP_TENANT: "ascensionidqa.onmicrosoft.com",
    REACT_APP_SIGN_IN_POLICY: "B2C_1A_signup_signin",
    REACT_APP_EDIT_PROFILE_POLICY: "B2C_1A_profile_edit",
    REACT_APP_FORGOT_PASSWORD_POLICY: "B2C_1A_Password_Reset",
    REACT_APP_APPLICATION_ID: "33bb4c98-7fee-4a30-bbee-479c5e46ea24",
    REACT_APP_SCOPE: [
      "https://ascensionidqa.onmicrosoft.com/associate-messaging-service-api/app.access",
    ],
    REACT_APP_API_KEY_URI: "AnQeo6d45VjAxmQpCsvqLX0amyRYGuAn",
    REACT_APP_REDIRECT_URI:
      "https://staff-messaging-service-web-qa.app.cloud-02.pcf.ascension.org/sendmessage",
    REACT_APP_NOTIFICATION_GET_GROUPS_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/sms/group",
    REACT_APP_NOTIFICATION_POST_NEW_GROUP_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/sms/group/create",
    REACT_APP_NOTIFICATION_POST_UPDATE_GROUP_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/sms/group/update",
    REACT_APP_NOTIFICATION_GROUP_SUBSCRIBERS_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/sms/subscribers",
    REACT_APP_NOTIFICATION_BROADCAST_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/sms/b/broadcast",
    REACT_APP_MESSAGE_BROADCAST_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/sms/readall",
    REACT_APP_NOTIFICATION_AUTHENTICATE_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/sms/authenticate",
    REACT_APP_TOTAL_SUBSCRIBERS_REPORT_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/sms/report/subscriber-complete-details",
    REACT_APP_DELIVERY_STATUS_REPORT_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/sms/twilio/smsmessagereport",
    REACT_APP_ALL_USERS_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/user/usersByStates",
    REACT_APP_CREATE_USERS_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/user/createorupdate",
    REACT_APP_UPDATE_USERS_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/user/updaterole",
    REACT_APP_TWILIO_AREA_CODE_API:
      "https://test-api.ascension.org/sms/notification/internal/sms/group/phonenumbers",
    REACT_APP_GET_SCHEDULED_MESSAGES_API:
      "https://test-api.ascension.org/sms/notification/internal/scheduledMessage/all",
    REACT_APP_GET_MESSAGES_HISTORY_API:
      "https://test-api.ascension.org/sms/notification/internal/sms/messageHistory",
    REACT_APP_GET_STATES_API:
      "https://test-api.ascension.org/sms/notification/internal/sms/group/states",
    REACT_APP_GET_GROUPS_BY_STATE_API:
      "https://test-api.ascension.org/sms/notification/internal/user/findGroupsByState",
    REACT_APP_REPORTS_API:
      "https://test-api.ascension.org/sms/notification/internal/report/filter-report",
    REACT_APP_SUBSCRIBER_DETAILS_REPORT_API:
      "https://test-api.ascension.org/sms/notification/internal/sms/report/subscriber-complete-details",
    REACT_APP_DELETE_USER_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/user/deleteRole",
    REACT_APP_ADD_IMAGE_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/sms/sendMMS",
    REACT_APP_REPORT_CSV_EXPORT_URI:
      "https://test-api.ascension.org/sms/notification/internal/report/csv/message-delivery",
    REACT_APP_GROUPS_FOR_MESSAGE_URI:
      "https://test-api.ascension.org/sms/notification/internal/sms/group/groupsForMessage",
    REACT_APP_ALL_USERS_OLD_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/user",
    REACT_APP_USERS_BY_STATES_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/user/usersByStates",
    REACT_APP_GROUPS_BY_STATES_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/user/findGroupsByStates",
    REACT_APP_DELETE_SCHEDULED_MESSAGES_API_URI:
      "https://test-api.ascension.org/sms/notification/internal/scheduledMessage/delete",
  },
  uat: {
    REACT_APP_INSTANCE: "https://id-ua.ascension.org/",
    REACT_APP_TENANT: "id-ua.ascension.org",
    REACT_APP_SIGN_IN_POLICY: "B2C_1A_signup_signin",
    REACT_APP_EDIT_PROFILE_POLICY: "B2C_1A_profile_edit",
    REACT_APP_FORGOT_PASSWORD_POLICY: "B2C_1A_Password_Reset",
    REACT_APP_APPLICATION_ID: "f9f4b832-b50e-4cdb-bd7f-412072266448",
    REACT_APP_SCOPE: [
      "https://id-ua.ascension.org/associate-messaging-service-api/app.access",
    ],
    REACT_APP_API_KEY_URI: "AnQeo6d45VjAxmQpCsvqLX0amyRYGuAn",
    REACT_APP_REDIRECT_URI:
      "https://staff-messaging-service-web-uat.app.cloud-02.pcf.ascension.org/sendmessage",
    REACT_APP_NOTIFICATION_GET_GROUPS_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/group",
    REACT_APP_NOTIFICATION_POST_NEW_GROUP_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/group/create",
    REACT_APP_NOTIFICATION_POST_UPDATE_GROUP_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/group/update",
    REACT_APP_NOTIFICATION_GROUP_SUBSCRIBERS_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/subscribers",
    REACT_APP_NOTIFICATION_BROADCAST_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/b/broadcast",
    REACT_APP_MESSAGE_BROADCAST_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/readall",
    REACT_APP_NOTIFICATION_AUTHENTICATE_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/authenticate",
    REACT_APP_TOTAL_SUBSCRIBERS_REPORT_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/report/subscriber-complete-details",
    REACT_APP_DELIVERY_STATUS_REPORT_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/twilio/smsmessagereport",
    REACT_APP_ALL_USERS_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/user/usersByStates",
    REACT_APP_CREATE_USERS_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/user/createorupdate",
    REACT_APP_UPDATE_USERS_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/user/updaterole",
    REACT_APP_TWILIO_AREA_CODE_API:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/group/phonenumbers",
    REACT_APP_GET_SCHEDULED_MESSAGES_API:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/scheduledMessage/all",
    REACT_APP_GET_MESSAGES_HISTORY_API:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/messageHistory",
    REACT_APP_GET_STATES_API:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/group/states",
    REACT_APP_GET_GROUPS_BY_STATE_API:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/user/findGroupsByState",
    REACT_APP_REPORTS_API:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/report/filter-report",
    REACT_APP_SUBSCRIBER_DETAILS_REPORT_API:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/report/subscriber-complete-details",
    REACT_APP_DELETE_USER_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/user/deleteRole",
    REACT_APP_ADD_IMAGE_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/sendMMS",
    REACT_APP_REPORT_CSV_EXPORT_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/report/csv/message-delivery",
    REACT_APP_GROUPS_FOR_MESSAGE_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/sms/group/groupsForMessage",
    REACT_APP_ALL_USERS_OLD_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/user",
    REACT_APP_USERS_BY_STATES_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/user/usersByStates",
    REACT_APP_GROUPS_BY_STATES_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/user/findGroupsByStates",
    REACT_APP_DELETE_SCHEDULED_MESSAGES_API_URI:
      "https://staff-messaging-service-api-uat.app.cloud-02.pcf.ascension.org/notification/internal/scheduledMessage/delete",
  },

  prod: {
    REACT_APP_INSTANCE: "https://id.ascension.org/",
    REACT_APP_TENANT: "id.ascension.org",
    REACT_APP_SIGN_IN_POLICY: "B2C_1A_signup_signin",
    REACT_APP_EDIT_PROFILE_POLICY: "B2C_1A_profile_edit",
    REACT_APP_FORGOT_PASSWORD_POLICY: "B2C_1A_Password_Reset",
    REACT_APP_APPLICATION_ID: "064661a7-da59-4129-b0e7-217fedec87a4",
    REACT_APP_SCOPE: [
      "https://id.ascension.org/associate-messaging-service-api/app.access",
    ],
    REACT_APP_API_KEY_URI: "zeG96ZdvjGAnwMv5U8AZvvMrHJm25SDF",
    REACT_APP_REDIRECT_URI:
      "https://staff-messaging-service-web.pub.cloud-03.pcf.ascension.org/sendmessage",
    REACT_APP_NOTIFICATION_GET_GROUPS_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/sms/group",
    REACT_APP_NOTIFICATION_POST_NEW_GROUP_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/sms/group/create",
    REACT_APP_NOTIFICATION_POST_UPDATE_GROUP_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/sms/group/update",
    REACT_APP_NOTIFICATION_GROUP_SUBSCRIBERS_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/sms/subscribers",
    REACT_APP_NOTIFICATION_BROADCAST_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/sms/b/broadcast",
    REACT_APP_MESSAGE_BROADCAST_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/sms/readall",
    REACT_APP_NOTIFICATION_AUTHENTICATE_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/sms/authenticate",
    REACT_APP_TOTAL_SUBSCRIBERS_REPORT_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/sms/report/subscriber-complete-details",
    REACT_APP_DELIVERY_STATUS_REPORT_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/sms/twilio/smsmessagereport",
    REACT_APP_ALL_USERS_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/user/usersByStates",
    REACT_APP_CREATE_USERS_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/user/createorupdate",
    REACT_APP_UPDATE_USERS_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/user/updaterole",
    REACT_APP_TWILIO_AREA_CODE_API:
      "https://prod-api.ascension.org/sms/notification/internal/sms/group/phonenumbers",
    REACT_APP_GET_SCHEDULED_MESSAGES_API:
      "https://prod-api.ascension.org/sms/notification/internal/scheduledMessage/all",
    REACT_APP_GET_MESSAGES_HISTORY_API:
      "https://prod-api.ascension.org/sms/notification/internal/sms/messageHistory",
    REACT_APP_GET_STATES_API:
      "https://prod-api.ascension.org/sms/notification/internal/sms/group/states",
    REACT_APP_GET_GROUPS_BY_STATE_API:
      "https://prod-api.ascension.org/sms/notification/internal/user/findGroupsByState",
    REACT_APP_REPORTS_API:
      "https://prod-api.ascension.org/sms/notification/internal/report/filter-report",
    REACT_APP_SUBSCRIBER_DETAILS_REPORT_API:
      "https://prod-api.ascension.org/sms/notification/internal/sms/report/subscriber-complete-details",
    REACT_APP_DELETE_USER_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/user/deleteRole",
    REACT_APP_ADD_IMAGE_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/sms/sendMMS",
    REACT_APP_REPORT_CSV_EXPORT_URI:
      "https://prod-api.ascension.org/sms/notification/internal/report/csv/message-delivery",
    REACT_APP_GROUPS_FOR_MESSAGE_URI:
      "https://prod-api.ascension.org/sms/notification/internal/sms/group/groupsForMessage",
    REACT_APP_ALL_USERS_OLD_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/user",
    REACT_APP_USERS_BY_STATES_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/user/usersByStates",
    REACT_APP_GROUPS_BY_STATES_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/user/findGroupsByStates",
    REACT_APP_DELETE_SCHEDULED_MESSAGES_API_URI:
      "https://prod-api.ascension.org/sms/notification/internal/scheduledMessage/delete",
  },
};

export const getEnvVars = (host) => {
  switch (true) {
    case host.includes("localhost"):
      return envVars.local;
    case host.includes("-dev."):
      return envVars.dev;
    case host.includes("-qa."):
      return envVars.qa;
    case host.includes("-uat."):
      return envVars.uat;
    case host.includes("-web.pub.cloud-03"):
      return envVars.prod;
    default:
      console.error("Failed to load configuration", {
        code: "env_not_detected",
        desc: "host url does not match known environments",
      });
      return {};
  }
};
