/* eslint-disable */
import React from 'react';
import {styles} from '../../../Stylesheet.css';
import {Paper} from '@material-ui/core';
import MaterialTable from 'material-table';

const SubscriberDetails = ({tableData = {}}) => {
    const globalStyles = styles();

    return (
        <Paper style={{margin: "20px 0"}}>
            <MaterialTable
                title=""
                data={tableData.subscribersList}
                options={{
                    draggable: false,
                    toolbar: false,
                    search: false,
                    maxBodyHeight: '40vh',
                    headerStyle: {
                        background: "linear-gradient(to right, #1b4297, #8490d9)",
                        font: "Whitney, sans-serif",
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#ffffff",
                    },
                    pageSize: 5,
                    pageSizeOptions: [1, 5, 10, 20]
                }}
                columns={[
                    {field: "groupName", title: "Group Name"},
                    {
                        field: "name", title: "Subscriber Name", render: rowData => {
                            return (
                                rowData.firstName + ' ' + rowData.lastName
                            );
                        }
                    },
                    {field: "subscriberNumber", title: "Subscriber Number"},
                    {field: "status", title: "Subscriber Status"},
                    {field: "created", title: "Created (UTC)"},

                ]}
            />
        </Paper>
    );
};

export default SubscriberDetails;