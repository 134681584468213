import React from 'react';
import {styles} from '../../Stylesheet.css';
import {
    Container,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Paper
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withAuth} from '../../auth/AuthProvider';
const Row = ({title, children}) => {

    const {messageGuidelinesRow, messageGuidelinesRowTitle, messageGuidelinesRowBody} = styles();

    return (
        <TableRow className={messageGuidelinesRow}>
            <TableCell
                expandIcon={<ExpandMoreIcon/>}>
                <Typography className={messageGuidelinesRowTitle}>{title}</Typography>
            </TableCell>
            <TableCell>
                <Typography className={messageGuidelinesRowBody}>{children}</Typography>
            </TableCell>
        </TableRow>
    );
}

const ProfilePage = ({auth}) => {
    const globalStyles = styles();
    return (
        <>
            <Container style={{paddingTop: "30px", paddingBottom: "30px"}}>
                <Typography className={globalStyles.messageGuidelinesHeader}>My Profile</Typography>
                <Typography className={globalStyles.messageGuidelinesHeaderSubtext}></Typography>
                <Paper>
                    <TableContainer className={globalStyles.messageGuidelinesTable}>
                        <TableBody>
                            <Row title="Full Name">{auth.account.name}
                            </Row>
                            <Row title="Family Name">{auth.account.idToken.family_name}
                            </Row>
                            <Row title="Given Name">{auth.account.idToken.given_name}
                            </Row>
                            <Row title="Email Address">{auth.account.idToken.email}</Row>
                            <Row title="Role">
                                {auth.account.idToken.user_roles.includes("Nurse_DSSMS_APP_ADMIN") ? "App Admin" : auth.account.idToken.user_roles.includes("Nurse_DSSMS_GROUP_ADMIN") ? "Group Admin" : "Publisher"}
                            </Row>
                        </TableBody>
                    </TableContainer>
                </Paper>
            </Container>
        </>
    );
}

export default (withAuth(ProfilePage))