/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    FormLabel,
    FormHelperText,
    LinearProgress,
    Select,
    MenuItem,
} from '@material-ui/core';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';


const MessageScheduleModal = ({
                                  isOpen = false,
                                  isLoading = false,
                                  handleClose = () => {
                                  },
                                  handleScheduleMessage = () => {
                                  },
                              }) => {


    const [date, setDate] = useState(moment().format('L'));
    const [dateError, setDateError] = useState(null);
    const [time, setTime] = useState("");
    const [timeError, setTimeError] = useState(null);

    useEffect(() => {
        if (isOpen) {
            setDate(moment().format('L'));
            setTime("");
            setDateError(null);
            setTimeError(null);
        }
    }, [isOpen]);

    const generateTimes = () => {

        const isToday = moment().format('MM/DD/YYYY') === moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY');
        console.log(isToday,  moment().diff(moment(date, "MM/DD/YYYY"), 'days'));
        const start = isToday ? moment() : moment(date+'00:00', "MM/DD/YYYY HH:mm").subtract(5, "minutes");
        const startTime = moment(start).add(15 - moment(start).minute() % 15, 'minutes');
        const endTime = moment(date+'23:59', "MM/DD/YYYY HH:mm");
        const timeStops = [];

        while (startTime < endTime) {
            timeStops.push((new moment(startTime)).format('hh:mm A'));
            startTime.add(15, 'minutes');
        }


        //return {timesArray: timeStops.map(stop=>stop.split(' ')[0]), amPm: [...new Set(timeStops.map(stop=>stop.split(' ')[1]))]};
        return {timesArray: timeStops};
    }

    // DATE FUNCTION
    const handleChangeDate = e => {
        if (!!dateError) setDateError(null);
        if(!!timeError) setTimeError(null)
        setDate(moment(e).format('L'));
    }
    // TIME FUNCTION
    const handleChangeTime = e => {
        if (!!timeError) setTimeError(null);
        setTime(e.target.value);
    }

    const onClickSchedule = () => {
        let valid = true;
        const scheduledDate = new Date(date);
        const scheduledDate2 = scheduledDate.setHours(0, 0, 0, 0);
        const currentDate = new Date(moment().format("MM/DD/YYYY"));
        const currentDate2 = currentDate.setHours(0, 0, 0, 0);
        const scheduledTime = moment(`${date} ${time}`).format('MM-DD-YYYY HH:mm:ss')
        const currentTime = moment().tz('America/Chicago').format('MM-DD-YYYY HH:mm:ss')

        // VALIDATE DATE
        if (!date || date.length === 0 || scheduledDate2 < currentDate2) {
            valid = false;
            setDateError("Please select a valid date in future");
        }
        // VALIDATE TIME
        if (!time || time.length === 0 || scheduledTime < currentTime) {
            valid = false;
            setTimeError("Please select a valid time in CST time zone");
        }
        // VALIDATE AMPM
        // if (!amPm || amPm.length === 0) {
        //     valid = false;
        //     setTimeError("Please select a value from the dropdown");
        // }

        if (valid) {
            const dateTime = moment(`${date} ${time}`).format('MM-DD-YYYY HH:mm:ss');
            handleScheduleMessage(dateTime);
        }
    }

    return (
        <Dialog open={isOpen} onClose={handleClose} scroll="body" aria-labelledby="form-dialog-title" maxWidth="lg">
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DialogTitle id="form-dialog-title">Schedule Message</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justify="space-between" direction="row">
                        <Grid item xs={5}>
                            <DatePicker
                                variant="static"
                                openTo="date"
                                disablePast
                                disableToolbar
                                value={date}
                                // onChange={e => setDate(moment(e).format('L'))}
                                onChange={handleChangeDate}
                            />
                        </Grid>
                        <Grid item xs={5} container spacing={4} direction="column">
                            <Grid item container direction="column" spacing={2}>
                                <Grid item>
                                    <FormLabel>Date to send</FormLabel>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        required
                                        value={date}
                                        onChange={e => setDate(e.target.value)}
                                        // onChange={handleChangeDate}
                                    />
                                </Grid>
                                <Grid item>
                                    {dateError && <FormHelperText error>{dateError}</FormHelperText>}
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" spacing={2}>
                                <Grid item>
                                    <FormLabel>Time to send (CST)</FormLabel>
                                </Grid>
                                {/*<Grid item container direction="row" spacing={2}>*/}
                                    <Grid item container direction="column" >
                                        <Select
                                            variant="outlined"
                                            required
                                            value={time}
                                            // onChange={e => setTime(e.target.value)}
                                            onChange={handleChangeTime}
                                        >
                                            {generateTimes().timesArray.map(t => <MenuItem key={`time-select-item-${t}`}
                                                                                           value={t}>{t}</MenuItem>)}
                                        </Select>
                                    </Grid>
                                    {/*<Grid item>*/}
                                    {/*    <Select*/}
                                    {/*        variant="outlined"*/}
                                    {/*        required*/}
                                    {/*        value={amPm}*/}
                                    {/*        onChange={e => setAmPm(e.target.value)}*/}
                                    {/*    >*/}
                                    {/*        {generateTimes().amPm.filter(a=>time).map(t => <MenuItem key={`amPm-select-item-${t}`}*/}
                                    {/*                                                 value={t}>{t}</MenuItem>)}*/}
                                    {/*    </Select>*/}
                                    {/*</Grid>*/}
                                {/*</Grid>*/}
                                <Grid item>
                                    {timeError && <FormHelperText error>{timeError}</FormHelperText>}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={onClickSchedule}
                                >
                                    Schedule
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                {isLoading && <LinearProgress/>}
            </MuiPickersUtilsProvider>
        </Dialog>
    )
}

export default MessageScheduleModal;