/* eslint-disable */
import React from 'react';
import {styles} from '../../../Stylesheet.css';
import {Typography} from '@material-ui/core';

import TotalSubscribersReport from './TotalSubscriberReport';
import SubscriberDetails from './SubscriberDetails';

const SubscriberReportsController = ({tableData}) => {
    const globalStyles = styles();
    return (
        <>
            {tableData.showTotalSubscriberReport &&
            <>
                <Typography className={globalStyles.reportTableHeader}>Total Subscribers</Typography>
                <TotalSubscribersReport tableData={tableData.data}/>
            </>}
            {tableData.showSubscriberCompleteDetails &&
            <>
                <Typography className={globalStyles.reportTableHeader}>Subscriber Details</Typography>
                <SubscriberDetails tableData={tableData.data}/>
            </>}
        </>
    );
};

export default SubscriberReportsController;