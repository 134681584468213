/* eslint-disable */
import React, {useState, useEffect} from 'react';
import MaterialTable, {MTableHeader} from 'material-table';
import {
    Paper,
    Link,
    FormControlLabel,
    Checkbox,
    TextField,
    Grid,
    TableRow,
    TableCell,
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {useHistory} from 'react-router-dom';
import {styles} from "../../Stylesheet.css";
import withAxios from '../common/withAxios';
import {getEnvVars} from "../utils/env-utils";
import Typography from "@material-ui/core/Typography";
import {withAuth} from '../../auth/AuthProvider';
var theEnv = getEnvVars(window.location.host);

const UsersList = ({
                       title = "",
                       handleEdit = () => {},
                       setUsers = () => {},
                       tableRef,
                       axios,
    auth
                   }) => {
    const globalStyles = styles();
    const history = useHistory();

    const [allStates, setAllStates] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        if(allStates.length === 0)
            axios.get(theEnv.REACT_APP_GET_STATES_API).then(({data}) => {
                setAllStates(data);
            }).catch(e => console.error(e))
    }, []);

    const renderSelectOption = (option, {selected}) => {
        return (
            <FormControlLabel
                control={<Checkbox checked={selected} name={option}/>}
                label={option}
            />
        )
    }

    const handleChangeSelect = (event, value, reason) => {
        setSelectedStates(value);
        if (tableRef.current) tableRef.current.onQueryChange();
    };

    return (
        <Paper className={globalStyles.tableTitles} style={{margin: "30px 0"}} elevation={5}>
            <MaterialTable tableRef={tableRef}
                           localization={{
                               body: {
                                   emptyDataSourceMessage: (
                                       <Typography className={globalStyles.noRecords}>
                                           No records available.
                                       </Typography>
                                   ),
                               },
                           }}
                title={title}
                data={query => new Promise((resolve, reject) => {
                        axios.post(theEnv.REACT_APP_ALL_USERS_API_URI, {
                            states: selectedStates,
                            pageNumber: query.page,
                            pageSize: query.pageSize,
                            searchString: query.search,
                            sort: [{
                                orderBy: (query.orderBy && query.orderBy.field) || "email",
                                orderDirection: query.orderDirection || "asc"
                            }]
                        }).then(({data}) => {
                            setUsers(data.content);
                            resolve({
                                data: data.content || [],
                                page: query.page,
                                totalCount: data.totalElements,
                            })
                        }).catch(e => {
                            setUsers([]);
                            resolve({
                                data: [],
                                page: query.page,
                                totalCount: 0,
                            })
                        })
                    })
                }
                options={{
                    draggable: false,
                    headerStyle: {
                        color: "#1B4297",
                        fontFamily: "Whitney, sans-serif",
                        fontSize: "20px",
                        fontWeight: "600",
                    },
                    pageSize,
                    pageSizeOptions: [5, 10, 20, 50],
                }}
                onChangePage={page => setPageNumber(page)}
                onChangeRowsPerPage={pageSize => setPageSize(pageSize)}
                columns={[
                    {field: 'email', title: 'Email Address'},
                    {
                        title: 'Role',
                        sorting: false,
                        render: ({roles}) => {
                            return ((roles[roles.length - 1])) === "Nurse_DSSMS_PUBLISHER" ? "PUBLISHER" :
                                ((roles[roles.length - 1])) === "Nurse_DSSMS_GROUP_ADMIN" ? "GROUP ADMIN" :
                                    ((roles[roles.length - 1])) === "Nurse_DSSMS_APP_ADMIN" ? "APPLICATION ADMIN" :
                                    ((roles[roles.length - 1]))

                        }
                    },

                    {
                        render: rowData => {
                            return (
                                <Link
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        handleEdit(rowData.userId)
                                    }}>
                                    Edit Details
                                </Link>
                            );
                        }

                    }
                ]}
                components={{
                    Header: props => {
                        console.log(props)
                        return (
                            <>
                                <MTableHeader {...props} />
                                {auth.account.idToken.user_roles.includes("Nurse_DSSMS_APP_ADMIN") &&
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={4} container direction="row" alignItems="center">
                                                <Grid item xs={2}>State</Grid>
                                                <Grid item xs={7}>
                                                    <Autocomplete
                                                        fullWidth
                                                        multiple
                                                        disableCloseOnSelect
                                                        onChange={handleChangeSelect}
                                                        value={selectedStates}
                                                        id="stateSelect"
                                                        options={allStates}
                                                        getOptionLabel={option => option}
                                                        ChipProps={{
                                                            color: "primary",
                                                            variant: "outlined",
                                                            size: "small"
                                                        }}
                                                        renderInput={params => (
                                                            <TextField
                                                                fullWidth
                                                                placeholder={selectedStates.length > 0 ? "" : "All States"}
                                                                style={{width: "100%"}}
                                                                {...params}
                                                            />)}
                                                        renderOption={renderSelectOption}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>}

                            </>

                        )
                    }
                }}
            />
        </Paper>
    )
}

export default withAuth(withAxios(UsersList));