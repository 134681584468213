/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {Paper, Typography, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import formatPhoneNumber from '../utils/formatPhoneNumber';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import EditGroupDetailsModal from './EditGroupDetailsModal';
import {getEnvVars} from '../utils/env-utils';
import {withAuth} from '../../auth/AuthProvider';
import CardMedia from '@material-ui/core/CardMedia';
import hometitle from "../../Assets/hometitle.png";
import nursehallway from "../../Assets/nurse_hallway.jpg";
import withAxios from '../common/withAxios';

var theEnv = getEnvVars(window.location.host);
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        marginBottom: 30,
    },
    paperContent: {
        backgroundColor: '#EEE',
        boxShadow: "none",
    },
    leftIcon: {
        height: "12px",
        width: "18px",
        color: "#00A791",
    },
    backToDashboard: {
        height: "20px",
        width: "127px",
        color: "#1E69D2",
        fontFamily: "Whitney",
        fontSize: "18px",
        fontWeight: "500",
        letterSpacing: 0,
        lineHeight: "20px",
        textDecoration: "none",
    },
    spacing: {
        paddingTop: "22px",
        paddingBottom: "22px",
        paddingLeft: "0px",
        paddingRight: "23px"
    },
    groupName: {
        height: "56px",
        width: "100%",
        color: "#222222",
        fontFamily: "Chronicle Text G1",
        fontSize: "48px",
        fontWeight: 600,
        letterSpacing: "0.5px",
        lineHeight: "56px",
        marginBottom: 30,
        paddingBottom: "52px",
        borderBottom: "2px solid #B40187"

    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    twilioNumber: {
        height: "32px",
        width: "100%",
        color: "#6D6D6E",
        fontFamily: "Whitney",
        fontSize: "24px",
        fontWeight: "500",
        letterSpacing: 0,
        lineHeight: "32px",
        marginLeft: 0,
    },
    card: {
        display: "block",
        backgroundColor: "#FFFFFF",
        marginRight: 30,
        marginLeft: 0,
        marginTop: 30,
    },
    card1: {
        color: "#1B4297",
        fontFamily: "Chronicle Text G1",
        fontSize: "24px",
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: "31px",
        paddingTop: "10px",
        paddingBottom: "15px"
    },
    totalSubscribers: {
        marginBottom: "15px"
    },
    subscribersCount: {
        float: "right"
    },
    tags: {
        backgroundColor: "rgba(68,215,182,0.4)",
        color: "#1B4297",
        fontWeight: "bold",
        marginTop: 15
    },
    addTag: {
        float: "right",
        margin: 30,
    },
    link: {
        fontSize: "13px",
        fontWeight: 200,
        color: "#1A4297",
    },
    checkboxLabel: {
        fontSize: "0.875rem"
    },

}));

const GroupInfo = ({axios}) => {
    const history = useHistory();
    const [isEditGroupDetailsModalOpen, setEditGroupDetailsModalOpen] = useState(false);
    const [groupDetails, setGroupDetails] = React.useState({
        groupId: '',
        groupName: '',
        description: '',
        twilioNumbers: '',
        number: '',
        totalSubscribers: '',
        activeSubscribers: '',
        recipientsCount: '',
        usersWithAccess: [],
    });

    // Update Group
    const handleUpdateGroup = (groupName, groupPhoneNumber) => {
        const twilioNumbers = [
            {
                'type': groupDetails.twilioNumbers[0].type,
                'number': groupPhoneNumber
            }
        ]

        const groupId = groupDetails.groupId
        const updatedGroup = {groupId, groupName, twilioNumbers};

        axios.post(theEnv.REACT_APP_NOTIFICATION_POST_UPDATE_GROUP_API_URI, updatedGroup)
            .then(({data}) => {
                setGroupDetails({
                    ...groupDetails,
                    groupName: groupName,
                    twilioNumbers: twilioNumbers,
                    number: twilioNumbers[0].number,
                });
                setEditGroupDetailsModalOpen(false);
            })
            .catch(e => {
                console.error(e);
            })
    }

    const path = history.location.pathname;
    const groupId = path.substring(path.lastIndexOf("/") + 1);
    useEffect(() => {
        axios.get(`${theEnv.REACT_APP_NOTIFICATION_GET_GROUPS_API_URI}/${groupId}`, {})
            .then(groupRes => {
                let {
                        groupId,
                        groupName,
                        description,
                        twilioNumbers,
                        totalSubscribers,
                        activeSubscribers,
                        recipientsCount,
                        usersWithAccess
                    } = groupRes.data

                setGroupDetails({
                    ...groupDetails,
                    groupId: groupId,
                    groupName: groupName,
                    description: description,
                    twilioNumbers: twilioNumbers,
                    number: twilioNumbers[0].number,
                    totalSubscribers: totalSubscribers,
                    activeSubscribers: activeSubscribers,
                    recipientsCount: recipientsCount,
                    usersWithAccess: usersWithAccess,
                })

            })
            .catch (e => console.error(e))
    }, [groupId]);
    console.log("Rec, act, inac", groupDetails.activeSubscribers, groupDetails.recipientsCount, groupDetails.totalSubscribers)
    const usersWithAdminAccess = groupDetails.usersWithAccess.filter(function (el) {
        return el.roles.includes("Nurse_DSSMS_GROUP_ADMIN")
    });
    const groupAdminEmails = usersWithAdminAccess.map(g => g.email)
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container >
                <Grid item xs={12}>
                    <Paper className={classes.paperContent}>

                        <Grid container className={classes.spacing} spacing={2}>
                            <Typography className={classes.groupName}>
                                {groupDetails.groupName}
                            </Typography>
                            <Typography className={classes.twilioNumber}>
                                {formatPhoneNumber(groupDetails.number)}
                            </Typography>
                            <EditGroupDetailsModal
                                isOpen={isEditGroupDetailsModalOpen}
                                handleClose={() => setEditGroupDetailsModalOpen(false)}
                                initialGroupName={groupDetails.groupName}
                                initialGroupPhoneNumber={groupDetails.number}
                                handleUpdateGroupInfo={handleUpdateGroup}
                                data={groupDetails}
                            />

                            <Grid item xs={4}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        className={classes.media}
                                        image={hometitle}
                                    />
                                    <CardContent>
                                        <Typography variant="h5" component="h2" className={classes.card1}>
                                            Stats
                                        </Typography>
                                        <Typography variant="body2" component="p" className={classes.totalSubscribers}>
                                            <div className={classes.totalSubscribers}>Total Subscribers: <span
                                                className={classes.subscribersCount}>{groupDetails.totalSubscribers}</span>
                                            </div>
                                            <div className={classes.totalSubscribers}>Active Subscribers: <span
                                                className={classes.subscribersCount}>{groupDetails.activeSubscribers}</span>
                                            </div>
                                            <div className={classes.totalSubscribers}>Number of Messages Sent: <span
                                                className={classes.subscribersCount}>{groupDetails.recipientsCount}</span>
                                            </div>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        className={classes.media}
                                        image={nursehallway}
                                    />
                                    <CardContent>
                                        <Typography variant="h5" component="h2" className={classes.card1}>
                                            Group Admins
                                        </Typography>
                                        <FormControl fullWidth component="fieldset"
                                                        style={{minHeight: '105px', overflow: 'scroll'}}>
                                            <FormGroup>
                                                {groupAdminEmails.map(email => <Typography variant="body2"
                                                                                            component="p">{email}</Typography>)}
                                            </FormGroup>
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default withAuth(withAxios(GroupInfo));