import React from 'react';
import {styles} from '../../Stylesheet.css';
import {AppBar} from "@material-ui/core";
import {Link} from 'react-router-dom';
import {withAuth} from '../../auth/AuthProvider';

export default withAuth(function Navbar({auth}) {

    const globalStyles = styles();

    return (
        <AppBar className={globalStyles.appheader}>
            <div className={globalStyles.links1}>
                <Link to="/sendmessage" className={globalStyles.links}>
                    SEND MESSAGE
                </Link>
                <Link to="/messagehistory" className={globalStyles.links}>
                    MESSAGE HISTORY
                </Link>
                <Link to="/groups" className={globalStyles.links}>
                    Manage Groups
                </Link>
                <Link to="/reports" className={globalStyles.links}>
                    Reports
                </Link>
                {(auth.account.idToken.user_roles.includes("Nurse_DSSMS_APP_ADMIN") || auth.account.idToken.user_roles.includes("Nurse_DSSMS_GROUP_ADMIN")) &&
                <Link to="/userpermissions" className={globalStyles.links}>
                    User Permissions
                </Link>}
            </div>
        </AppBar>
    )
})