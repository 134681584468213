import React, {useEffect, useState} from 'react';
import {styles} from '../../Stylesheet.css';
import MaterialTable from 'material-table';
import {Paper, Typography} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import {getEnvVars} from "../utils/env-utils";
import {withAuth} from "../../auth/AuthProvider";
import ConfirmationDialog from '../common/ConfirmationDialog';
import withAxios from '../common/withAxios';

var theEnv = getEnvVars(window.location.host);
const SchedulesMessageList = ({
                                  title = "Pending",
                                  data = [],
                                  groups = [],
                                  axios
                              }) => {
    const globalStyles = styles();
    
    const [tableData, setTableData] = useState(data);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [deleteMessageId, setDeleteMessageId] = useState();

    useEffect(() => {
        setTableData(data)
    }, [data]);
    const handleCancelSubmit = () => setIsConfirmationDialogOpen(false);

    const getMessages = () => axios.get(theEnv.REACT_APP_GET_SCHEDULED_MESSAGES_API)
    const handleDelete = messageId => {
        axios.post(theEnv.REACT_APP_DELETE_SCHEDULED_MESSAGES_API_URI, {
            messageIds: [messageId]
        }).then(() => {
                getMessages()
                    .then((messagesRes) => {
                        let sortedMessages = messagesRes.data
                            // .filter(m => groupIds.some(g => m.groupIds.split(",").includes(g))
                            //     && !m.messageTriggered && (new Date(m.scheduledDateTime.split('[')[0]) > new Date())
                            // )
                            .sort((a, b) => new Date(a.scheduledDateTime) - new Date(b.scheduledDateTime));
                        setTableData(sortedMessages);
                        setIsConfirmationDialogOpen(false);
                    })
            })
            .catch(e => {
                console.error(e);
            })
    }
    const handleConfirmSubmit = () => {
        setIsConfirmationDialogOpen(false);
        handleDelete(deleteMessageId);
    }
    return (
        <>
            <ConfirmationDialog
                title="Confirm Delete"
                isOpen={isConfirmationDialogOpen}
                text="Are you sure you want to delete this message?"
                handleCancel={handleCancelSubmit}
                handleConfirm={handleConfirmSubmit}
            />
        <div>
            <Typography className={globalStyles.tableTitle}>{title}</Typography>
            <Paper>
                <MaterialTable
                    title=""
                    data={tableData}
                    options={{
                        draggable: false,
                        headerStyle: {
                            color: "#1B4297",
                            fontFamily: "Whitney, sans-serif",
                            fontSize: "18px",
                            fontWeight: "600",
                        },
                        cellStyle: {
                            maxWidth: "100px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        },

                    }}
                    columns={[
                        {
                            title: "Target Groups",
                            render: rowData => {
                                let groupNamesArray = rowData.groupIds.split(",")
                                    .map(id => (groups.find(group => group.groupId.toString() === id)||{}).groupName);
                                if (groupNamesArray.length > 2)
                                    groupNamesArray = [...groupNamesArray.slice(0, 2), `+ ${groupNamesArray.length - 2} more`]

                                return groupNamesArray.map((n, i) => <Typography
                                    key={`message-${rowData.scheduledMessageId}-group-names-${i}`}
                                    className={globalStyles.messagesListGroupNameText}>{n}</Typography>)
                            },
                        },
                        {field: "message", title: "Message Text"},
                        {
                            title: "Scheduled Date and Time (CST)",
                            render: ({scheduledDateTime}) => new Date(scheduledDateTime.split('[')[0]).toLocaleString()
                        },
                        {
                            field: "Delete",
                            filtering: false,
                            render: ({scheduledMessageId}) => {
                                return (
                                    <Button
                                        data-test="accountsTable-row"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {setIsConfirmationDialogOpen(true); setDeleteMessageId(scheduledMessageId)}}
                                    >
                                        Delete
                                    </Button>
                                );
                            }
                        }
                    ]}
                />
            </Paper>
        </div>
            </>
    )
}

export default withAuth(withAxios(SchedulesMessageList));