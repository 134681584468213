/* eslint-disable */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {config, initMsalApp} from './auth-utils';
import jwt_decode from 'jwt-decode';

export const AuthContext = React.createContext(null);

export const withAuth = Component => props => (
    <AuthContext.Consumer>
        {auth => auth.baseAccessToken && <Component {...props} auth={auth}/>}
    </AuthContext.Consumer>
);

const AuthProvider = ({children}) => {
    const msalApp = initMsalApp();
    const [account, setAccount] = useState(msalApp.getAccount());
    const [baseAccessToken, setBaseAccessToken] = useState(null);
    const [error, setError] = useState(null);

    const aquireBaseToken = request =>
        msalApp
            .acquireTokenSilent(request)
            .then(res => {
                setBaseAccessToken(res.accessToken);
            })
            .catch(error => {
                console.log('Aquire Token Error:', error);
                if (error.name === 'InteractionRequiredAuthError') {
                    return msalApp.acquireTokenRedirect(request);
                } else if (error.errorCode === 'user_login_error') {
                    return msalApp.loginRedirect(config.scopes);
                } else if (error.errorCode === 'AuthError') {
                    return msalApp.loginRedirect(config.scopes);
                }
            });

    const logout = () => msalApp.logout();

    useEffect(() => {
        aquireBaseToken(config);
        msalApp.handleRedirectCallback(err => {
            if (err && err.errorCode !== 'login_progress_error') {
                console.log('Redirect Error', err);
                setError(err.message ? err.message : 'Unable to aquire access token');
            }
            else if(msalApp.getAccount().idToken.user_roles.length === 0) {
                logout()
            }

            const localAccount = msalApp.getAccount();
            if (localAccount) {
                setAccount(localAccount);
            }
            else {
                msalApp.loginRedirect(config.scopes);
            }
        });
    }, []);

    useEffect(() => {
        if (baseAccessToken) {
            // Worker
            const tokenWorker = new Worker('./workers/token-expiry-worker.js');
            tokenWorker.postMessage(jwt_decode(baseAccessToken));
            tokenWorker.onmessage = e => {
                if (e && e.data) {
                    tokenWorker.terminate();
                    logout();
                }
            }
        }
    }, [baseAccessToken])

    return (
        <AuthContext.Provider
            value={{
                account,
                baseAccessToken,
                logout,
                error,
                aquireBaseToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );

};

AuthProvider.propTypes = {
    children: PropTypes.object.isRequired
};

export default AuthProvider;