/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {styles} from '../../Stylesheet.css';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ScheduledMessagesList from './ScheduledMessagesList';
import SendMessageForm from './SendMessageForm';
import Navbar from "../common/Navbar";
import {getEnvVars} from '../utils/env-utils';
import {withAuth} from "../../auth/AuthProvider";
import withAxios from '../common/withAxios';

var theEnv = getEnvVars(window.location.host);

const SnackAlert = ({
                        isOpen = false, handleClose = () => {
    }, severity = "success", text = "Success!"
                    }) => (
    <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert elevation={6} variant="filled" onClose={handleClose} severity={severity}>
            {text}
        </Alert>
    </Snackbar>
)

const DashboardController = ({auth, axios}) => {
    const globalStyles = styles();
    
    const [scheduledMessages, setScheduledMessages] = useState([]);
    const [targetGroupsLoading, setTargetGroupsLoading] = useState(false);
    const [targetGroupsList, setTargetGroupsList] = useState([]);
    const [allGroupsList, setAllGroupsList] = useState([]);

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertText, setAlertText] = useState("Success!");

    const createAlert = (severity = "success", text = "Success!") => {
        setAlertSeverity(severity);
        setAlertText(text);
        setIsAlertOpen(true);
    };

    const getGroups = () => axios.get(theEnv.REACT_APP_GROUPS_FOR_MESSAGE_URI)

    const getMessages = (allGroups) => axios.get(theEnv.REACT_APP_GET_SCHEDULED_MESSAGES_API)
        .then((messagesRes) => {
            const groupIds = allGroups.map(g => g.groupId.toString());
            let sortedMessages = messagesRes.data
                .sort((a, b) => new Date(a.scheduledDateTime) - new Date(b.scheduledDateTime));
            setScheduledMessages(sortedMessages);
        });
    useEffect(() => {
        setTargetGroupsLoading(true);
        getGroups()
            .then(({data}) => {
                const sortedGroups = data.sort((a, b) => {
                    if (a.groupName < b.groupName) return -1;
                    else if (a.groupName === b.groupName) return 0;
                    else return 1;
                });
                setAllGroupsList(data);
                getMessages(data).catch(console.error);
                setTargetGroupsList(sortedGroups);
            })
            .finally(() => setTargetGroupsLoading(false));
    }, []);

    // Message Modal State
    const [messageSubmitError, setMessageSubmitError] = useState(null);

    const [isMessageSending, setIsMessageSending] = useState(false);

    const handleSubmitMessage = (message, onSuccess) => {
        setIsMessageSending(true);
        setMessageSubmitError(null);
        const formData = new FormData();
        if (message.file === undefined) {
            // formData.append('file', message.file);
            formData.append('groupIds', message.groupIds);
            formData.append('message', message.message);
            formData.append('requestedBy', auth.account.name);
            formData.append('scheduled', message.scheduled);
            formData.append('scheduledDateTime', message.scheduledDateTime);
        } else {
            formData.append('file', message.file);
            formData.append('groupIds', message.groupIds);
            formData.append('message', message.message);
            formData.append('requestedBy', auth.account.name);
            formData.append('scheduled', message.scheduled);
            formData.append('scheduledDateTime', message.scheduledDateTime);
        }
        axios.post(theEnv.REACT_APP_ADD_IMAGE_API_URI, formData)
            .then(({data}) => {
                if (message.scheduled) {
                    getMessages(allGroupsList).catch(console.error);
                    createAlert("success", "Message scheduled successfully");
                } else createAlert("success", "Message sent successfully");
                onSuccess();
            }).catch(e => {
            setMessageSubmitError("There was an error contacting the server to send your message. Please try again. If the error persists please contact the application administrator.");
            console.error(e);
        }).finally(() => setIsMessageSending(false));

    };

    return (
        <Container style={{padding: "30px 0 100px 0"}}>
            <Navbar/>
            <SendMessageForm
                groupsLoading={targetGroupsLoading}
                isMessageSending={isMessageSending}
                targetGroupsList={targetGroupsList}
                handleSubmit={handleSubmitMessage}
                messageSubmitError={messageSubmitError}
            />
            <ScheduledMessagesList
                title="Scheduled Messages"
                groups={targetGroupsList}
                data={scheduledMessages}
                editable={false}
            />
            <SnackAlert
                isOpen={isAlertOpen}
                handleClose={() => setIsAlertOpen(false)}
                severity={alertSeverity}
                text={alertText}
            />
        </Container>
    )
}

export default withAuth(withAxios(DashboardController));
