import React from 'react';
import {styles} from '../../../Stylesheet.css';
import {Typography} from '@material-ui/core';

import TotalGroupsReport from './TotalGroupsReport';
import GroupCreationDetails from './GroupCreationDetails';

const GroupCreationReportsController = ({tableData}) => {
    const globalStyles = styles();

    return (
        <>
            {tableData.showTotalGroupsReport &&
            <>
                <Typography className={globalStyles.reportTableHeader}>Total Groups</Typography>
                <TotalGroupsReport tableData={tableData.data}/>
            </>}
            {tableData.showGroupCreationDetails &&
            <>
                <Typography className={globalStyles.reportTableHeader}>Group Creation Details</Typography>
                <GroupCreationDetails tableData={tableData.data}/>
            </>}
        </>
    );
};

export default GroupCreationReportsController;