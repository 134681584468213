/* eslint-disable */
import React, {useState, useEffect, useRef} from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    FormLabel,
    FormControl,
    FormHelperText,
    TextField,
    Grid,
    LinearProgress,
    Typography
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {styles} from "../../Stylesheet.css";
import MaterialTable from "material-table";
import ConfirmationDialog from '../common/ConfirmationDialog';
import {getEnvVars} from '../utils/env-utils';
import {withAuth} from "../../auth/AuthProvider";
import withAxios from '../common/withAxios';

var theEnv = getEnvVars(window.location.host);

const GROUP_ADMIN = "Nurse_DSSMS_GROUP_ADMIN";
const PUBLISHER = "Nurse_DSSMS_PUBLISHER";

const UsersModal = ({
                        axios,
                        modalTitle = "User's Modal",
                        initialNotificationGroups = [],
                        availableStates = [],
                        isOpen = false,
                        isLoading = false,
                        isGroupsListLoading = false,
                        isUpdateModal = false,
                        handleClose = () => {
                        },
                        handleSubmit = () => {
                        },
                        handleDeleteUser = () => {
                        },
                        submitError = null,
                        initialState = "",
                        initialEmail = "",
                        initialFirstName = "",
                        initialLastName = "",
                        initialRole = "",
                        submitText = "Submit",
                        auth
                    }) => {
    const globalStyles = styles();

    const groupsTableRef = useRef()

    // FORM STATES
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [state, setState] = useState("");
    const [role, setRole] = useState("");
    const [currentRole, setCurrentRole] = useState("");
    const [allPageGroups, setAllPageGroups] = useState([]);
    const [selectedNotificationGroups, setSelectedNotificationGroups] = useState([]);

    // ERROR STATES
    const [emailError, setEmailError] = useState(null);
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [stateSelectError, setStateSelectError] = useState(null);
    const [roleError, setRoleError] = useState(null);
    const [selectedNotificationGroupsError, setSelectedNotificationGroupsError] = useState(null);

    // CONFIRMATION DIALOG STATES
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [confirmationDialogTitle, setConfirmationDialogTitle] = useState("Confirm Delete User");
    const [confirmationDialogText, setConrirmationDialogText] = useState("Are you sure you want to delete this user?");
    const [confirmationDialogConfirmFn, setConfirmationDialogConfirmFn] = useState(() => () => {
    })

    // INIT STATE
    useEffect(() => {
        if (isOpen) {
            setEmail(initialEmail || "");
            setFirstName(initialFirstName || "");
            setLastName(initialLastName || "");
            setState(initialState || "");
            setRole(initialRole || "");
            setSelectedNotificationGroups(initialNotificationGroups || []);
            setEmailError(null);
            setFirstNameError(null);
            setLastNameError(null);
            setStateSelectError(null);
            setRoleError(null);
            setSelectedNotificationGroupsError(null);
        }
    }, [isOpen]);

    useEffect(() => {
        if (groupsTableRef.current) groupsTableRef.current.onQueryChange();
    }, [state]);

    // STATE FUNCTIONS
    const handleChangeSelectState = (event, value, reason) => {
        if (stateSelectError !== null) setStateSelectError(null);
        setState(value);
    }

    // EMAIL FUNCTIONS
    const handleChangeEmail = e => {
        if (!!emailError) setEmailError(null);
        setEmail(e.target.value);
    }

    const handleChangeFirstName = e => {
        if (!!firstNameError) setFirstNameError(null);
        setFirstName(e.target.value);
    }
    const handleChangeLastName = e => {
        if (!!lastNameError) setLastNameError(null);
        setLastName(e.target.value);
    }

    // ROLE FUNCTIONS
    const handleChangeRole = e => {
        if (!!roleError) setRoleError(null);
        setRole(e.target.value);
        setCurrentRole(role)
    }

    // GROUPS FUNCTIONS
    const handleChangeGroups = rows => {
        let allSelectedGroups = [...selectedNotificationGroups];
        const pageIds = allPageGroups.map(group => group.groupId);
        const selectedPageIds = rows.map(group => group.groupId);
        pageIds.forEach(id => {
            if (allSelectedGroups.includes(id) && !selectedPageIds.includes(id)) {
                const index = allSelectedGroups.findIndex(i => i === id);
                allSelectedGroups.splice(index, 1);
            } else if (!allSelectedGroups.includes(id) && selectedPageIds.includes(id)) {
                allSelectedGroups.push(id);
            }
        });
        setSelectedNotificationGroups(allSelectedGroups);
    }

    const notificationGroups = query => new Promise((resolve, reject) => {
        axios.post(theEnv.REACT_APP_GET_GROUPS_BY_STATE_API, {
            state: state,
            pageNumber: query.page,
            pageSize: query.pageSize
        })
            .then(({data}) => {
                    setAllPageGroups(data.content);
                    let content = data.content.map(item => {
                        if (selectedNotificationGroups.length && selectedNotificationGroups.includes(item.groupId)) {
                            return { ...item, tableData: { checked: true } }
                        } else return item;
                    });
                    resolve({
                        data: content,
                        page: query.page,
                        totalCount: data.totalElements,
                    })
                }
            )
            .catch(e => {
                console.error(e);

                if(e.response.data === "User not assigned to selected State") {
                    setStateSelectError("User not assigned to selected State")}
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0,
                    })
            }); })
    // VALIDATE AND SUBMIT
    const validateAndCb = cb => {
        let valid = true;
        // VALIDATE EMAIL
        if (!email || email.trim() === "" ||
            !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            valid = false;
            setEmailError("Please enter a valid email address");
        }

        // VALIDATE FIRST NAME
        if (!firstName || firstName.trim() === "") {
            valid = false;
            setFirstNameError("Please enter a valid first name");
        }
        // VALIDATE LAST NAME
        if (!lastName || lastName.trim() === "") {
            valid = false;
            setLastNameError("Please enter a valid last name");
        }

        // VALIDATE STATE
        if (!state || state.trim() === "" || !availableStates.includes(state.trim())) {
            valid = false;
            setStateSelectError("Please select a valid state");
        }
        if(stateSelectError === "User not assigned to selected State"){
            valid =false
        }

        // VALIDATE ROLE
        if (!role || role.trim() === "" || (role !== GROUP_ADMIN && role !== PUBLISHER)) {
            valid = false;
            setRoleError("Please select a valid role");
        }

        if (valid && currentRole !== "") {
            const userData = {
                email: email.trim(),
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                state: state.trim(),
                newRoles: [role],
                currentRoles: [currentRole],
                notificationGroups: selectedNotificationGroups
            }
            cb(userData);
        }
        else if(valid && currentRole === "") {
            const userData = {
                email: email.trim(),
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                state: state.trim(),
                newRoles: [role],
                currentRoles: [role],
                notificationGroups: selectedNotificationGroups
            }
            cb(userData);
        }
    }

    const handleClickDelete = user => {
        setConfirmationDialogConfirmFn(() => () => {
            handleDeleteUser(user);
            setIsConfirmationDialogOpen(false);
        });
        setIsConfirmationDialogOpen(true);
    }
    const ascensionEmail = auth.account.idToken.email.toLowerCase();
    const smsEmail = email.toLowerCase();

    return (
        <>
            <ConfirmationDialog
                isOpen={isConfirmationDialogOpen}
                title={confirmationDialogTitle}
                text={confirmationDialogText}
                handleConfirm={confirmationDialogConfirmFn}
                handleCancel={() => setIsConfirmationDialogOpen(false)}
            />
            <Dialog open={isOpen} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
                <DialogContent>
                    {(isUpdateModal && ascensionEmail !==smsEmail) && <Typography className={globalStyles.helpertext}>***Update option is available for only Roles, States and Notification Groups***</Typography>}
                    {ascensionEmail === smsEmail && <Typography className={globalStyles.warningText}>User cannot update their own details</Typography> }
                    <FormControl
                        style={{margin: "20px 0"}}
                        fullWidth
                        variant="outlined"
                        error={!!emailError}
                        required>
                        <FormLabel>Email Address</FormLabel>
                        {isUpdateModal &&
                        <TextField
                            disabled
                            variant="outlined"
                            id="email"
                            value={email}
                            onChange={handleChangeEmail}
                            fullWidth
                            multiline
                        />}
                        {!isUpdateModal &&
                        <TextField
                            variant="outlined"
                            id="email"
                            value={email}
                            onChange={handleChangeEmail}
                            fullWidth
                            multiline
                        />}
                        {!!emailError && <FormHelperText>{emailError}</FormHelperText>}
                    </FormControl>
                    <FormControl
                        style={{margin: "20px 0"}}
                        fullWidth
                        variant="outlined"
                        error={!!firstNameError}
                        required>
                        <FormLabel>First Name</FormLabel>
                        {isUpdateModal &&
                        <TextField
                            disabled
                            variant="outlined"
                            id="firstName"
                            value={firstName}
                            onChange={handleChangeFirstName}
                            fullWidth
                            multiline
                        />}
                        {!isUpdateModal &&
                        <TextField
                            variant="outlined"
                            id="firstName"
                            value={firstName}
                            onChange={handleChangeFirstName}
                            fullWidth
                            multiline
                        />}
                        {!!firstNameError && <FormHelperText>{firstNameError}</FormHelperText>}
                    </FormControl>
                    <FormControl
                        style={{margin: "20px 0"}}
                        fullWidth
                        variant="outlined"
                        error={!!lastNameError}
                        required>
                        <FormLabel>Last Name</FormLabel>
                        {isUpdateModal &&
                        <TextField
                            disabled
                            variant="outlined"
                            id="lastName"
                            value={lastName}
                            onChange={handleChangeLastName}
                            fullWidth
                            multiline
                        />}
                        {!isUpdateModal &&
                        <TextField
                            variant="outlined"
                            id="lastName"
                            value={lastName}
                            onChange={handleChangeLastName}
                            fullWidth
                            multiline
                        />}
                        {!!lastNameError && <FormHelperText>{lastNameError}</FormHelperText>}
                    </FormControl>
                    {ascensionEmail !== smsEmail &&
                    <FormControl
                        fullWidth
                        required
                        error={!!stateSelectError}
                    >
                        <FormLabel>State</FormLabel>
                        <Autocomplete
                            fullWidth
                            onChange={handleChangeSelectState}
                            value={state}
                            id="stateSelect"
                            options={availableStates}
                            getOptionLabel={option => option}
                            renderInput={params => (
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    {...params}
                                />)}
                        />
                        {!!stateSelectError && <FormHelperText>{stateSelectError}</FormHelperText>}
                    </FormControl>}
                    {ascensionEmail === smsEmail &&
                    <FormControl
                        disabled
                        fullWidth
                        required
                        error={!!stateSelectError}
                    >
                        <FormLabel>State</FormLabel>
                        <Autocomplete
                            disabled
                            fullWidth
                            onChange={handleChangeSelectState}
                            value={state}
                            id="stateSelect"
                            options={availableStates}
                            getOptionLabel={option => option}
                            renderInput={params => (
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    {...params}
                                />)}
                        />
                        {!!stateSelectError && <FormHelperText>{stateSelectError}</FormHelperText>}
                    </FormControl>}
                    {ascensionEmail !== smsEmail &&
                    <FormControl
                        style={{margin: "20px 0"}}
                        fullWidth
                        variant="outlined"
                        error={!!roleError}
                        required>
                        <FormLabel>Role</FormLabel>
                        <Select
                            variant="outlined"
                            id="role"
                            value={role}
                            onChange={handleChangeRole}
                            fullWidth
                        >
                            <MenuItem value="" disabled>Select a Type</MenuItem>
                            <MenuItem value={GROUP_ADMIN}>Group Admin</MenuItem>
                            <MenuItem value={PUBLISHER}>Publisher</MenuItem>
                        </Select>
                        {!!roleError && <FormHelperText>{roleError}</FormHelperText>}
                    </FormControl>}
                    {ascensionEmail === smsEmail &&
                    <FormControl
                        style={{margin: "20px 0"}}
                        fullWidth
                        disabled
                        variant="outlined"
                        error={!!roleError}
                        required>
                        <FormLabel>Role</FormLabel>
                        <Select
                            variant="outlined"
                            id="role"
                            value={role}
                            onChange={handleChangeRole}
                            fullWidth
                            disabled
                        >
                            <MenuItem value="" disabled>Select a Type</MenuItem>
                            <MenuItem value={GROUP_ADMIN}>Group Admin</MenuItem>
                            <MenuItem value={PUBLISHER}>Publisher</MenuItem>
                        </Select>
                        {!!roleError && <FormHelperText>{roleError}</FormHelperText>}
                    </FormControl>}

                    {(state !== "" && ascensionEmail !== smsEmail) && <FormControl fullWidth>
                        <MaterialTable
                            title=""
                            tableRef={groupsTableRef}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: (
                                        <Typography className={globalStyles.noRecords}>
                                            No records available.
                                        </Typography>
                                    ),
                                },
                            }}
                            data={notificationGroups}
                            columns={[
                                {
                                    field: 'groupName', title: 'Group Name',
                                },
                            ]}
                            options={{
                                selection: true,
                                showTextRowsSelected: false
                            }}
                            onSelectionChange={handleChangeGroups}
                        />
                    </FormControl>}
                    {(state !== "" && ascensionEmail === smsEmail) && <FormControl fullWidth>
                        <MaterialTable
                            title=""
                            tableRef={groupsTableRef}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: (
                                        <Typography className={globalStyles.noRecords}>
                                            No records available.
                                        </Typography>
                                    ),
                                },
                            }}
                            data={notificationGroups}
                            columns={[
                                {
                                    field: 'groupName', title: 'Group Name',
                                },
                            ]}
                            options={{
                                selection: false,
                                showTextRowsSelected: false
                            }}
                            onSelectionChange={handleChangeGroups}
                        />
                    </FormControl>}
                    {submitError &&
                    <FormHelperText error>
                        {submitError}
                    </FormHelperText>}
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justify="space-between">
                        <Grid item>
                            {isUpdateModal && ascensionEmail !== smsEmail &&
                            <Button
                                onClick={() => validateAndCb(handleClickDelete)}
                                color="secondary"
                            >
                                Delete User
                            </Button>}
                        </Grid>
                        <Grid item>
                            <Button onClick={handleClose} disabled={isLoading}>
                                Cancel
                            </Button>
                            {ascensionEmail !== smsEmail &&
                            <Button onClick={() => validateAndCb(handleSubmit)}
                                    disabled={isLoading}
                                    className={globalStyles.hello}
                                    variant="contained" color="secondary">
                                {submitText}
                            </Button>}
                        </Grid>
                    </Grid>
                </DialogActions>
                {isLoading && <LinearProgress/>}
            </Dialog>
        </>
    )
}
export default withAuth(withAxios(UsersModal));