import React, { useState } from 'react';
import MaterialTable, {MTableHeader} from 'material-table';
import {
    Paper,
    IconButton,
    Link,
    FormControlLabel,
    Checkbox,
    TextField,
    Grid,
    TableRow,
    TableCell,
    Button,
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab'
import EditIcon from '@material-ui/icons/Edit';
import formatPhoneNumber from '../utils/formatPhoneNumber';
import {useHistory} from 'react-router-dom';
import {withAuth} from "../../auth/AuthProvider";
import {styles} from "../../Stylesheet.css";
import {getEnvVars} from '../utils/env-utils';
import withAxios from '../common/withAxios';

var theEnv = getEnvVars(window.location.host);

const GroupsList = ({
                        title = "",
                        statesOptions = [],
                        handleEdit = () => {
                        },
                        auth,
                        tableRef,
                        axios
                    }) => {
    const history = useHistory();
    const globalStyles = styles();

    const [selectedStates, setSelectedStates] = useState([]);
    const [previousSelectedStates, setPreviouslySelectedStates] = useState([]);

    const renderSelectOption = (option, {selected}) => {
        return (
            <FormControlLabel
                control={<Checkbox checked={selected} name={option}/>}
                label={option}
            />
        )
    }

    const handleChangeSelect = async (event, value, reason) => {
        setSelectedStates(value);
    }
    
    const handleRefreshData = () => {
        setPreviouslySelectedStates(selectedStates);
        if (tableRef.current) tableRef.current.onQueryChange();
    }

    return (
        <Paper className={globalStyles.tableTitles} style={{margin: "30px 0"}} elevation={0}>
            <MaterialTable
                title={title}
                tableRef={tableRef}
                data={query =>
                    new Promise((resolve, reject) => {
                        axios.post(theEnv.REACT_APP_NOTIFICATION_GET_GROUPS_API_URI, {
                                pageSize: query.pageSize,
                                pageNumber: query.page,
                                states: selectedStates,
                        })
                            .then(result => {
                                console.log({result})
                                resolve({
                                    data: result.data.content,
                                    page: query.page,
                                    totalCount: result.data.totalElements,
                                })
                            }).catch(e => {
                            console.error(e);
                            reject();
                        })
                    })
                }
                options={{
                    draggable: false,
                    headerStyle: {
                        color: "#1B4297",
                        fontFamily: "Whitney, sans-serif",
                        fontSize: "18px",
                        fontWeight: "600",
                    },
                    padding: 'dense',
                    search: false
                }}
                columns={[
                    {
                        render:
                            rowData =>
                                (auth.account.idToken.user_roles.includes("Nurse_DSSMS_APP_ADMIN") || auth.account.idToken.user_roles.includes("Nurse_DSSMS_GROUP_ADMIN")) &&
                                <IconButton edge="start" onClick={() => handleEdit(rowData)}>
                                    <EditIcon fontSize="small" style={{fill: "#1B4297"}}/>
                                </IconButton>,
                        width: 1
                    },
                    {
                        field: 'groupName', title: 'Messaging Group Name',
                        cellStyle: {
                            color: "#222222",
                            fontFamily: "Whitney, sans-serif",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                        },
                    },
                    {
                        field: 'phoneNumber', title: 'Phone Number',
                        render: rowData => {
                            return (
                                rowData.twilioNumbers.map((number, i, arr) =>
                                    <div key={`twilio-number-${number.id}`}>
                                        <span>{formatPhoneNumber(number.number)}</span>
                                        {i !== (arr.length - 1) && <hr/>}
                                    </div>
                                )
                            )
                        },
                    },
                    {
                        field: 'totalSubscribers', title: 'Recipients'
                    },
                    {
                        field: "seeAllGroupInfo",
                        filtering: false,
                        render: rowData => {
                            return (
                                <Link
                                    color="primary"
                                    onClick={() => {
                                        history.push(("/subscriberslist/" + rowData.groupId), rowData.groupId)
                                    }}>
                                    See All Group Details
                                </Link>
                            );
                        }
                    }
                ]}
                components={{
                    Header: props => (
                        <>
                            <MTableHeader {...props} />
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={4} container direction="row" alignItems="center">
                                            <Grid item xs={2}>State</Grid>
                                            <Grid item xs={9}>
                                                <Autocomplete
                                                    fullWidth
                                                    multiple
                                                    disableCloseOnSelect
                                                    onChange={handleChangeSelect}
                                                    value={selectedStates}
                                                    id="stateSelect"
                                                    options={statesOptions}
                                                    getOptionLabel={option => option}
                                                    ChipProps={{color: "primary", variant: "outlined", size: "small"}}
                                                    renderInput={params => (
                                                        <TextField
                                                            fullWidth
                                                            placeholder={selectedStates.length > 0 ? "" : "All States"}
                                                            style={{width: "100%"}}
                                                            {...params}
                                                        />)}
                                                    renderOption={renderSelectOption}
                                                />
                                            </Grid>
                                            {(previousSelectedStates.length !== selectedStates.length) && <Grid item xs={1}><Button onClick={handleRefreshData}>Apply</Button></Grid>}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        </>
                    )
                }}
            />
        </Paper>
    )
}

export default withAuth(withAxios(GroupsList));