/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,

} from '@material-ui/core';

export default function EditGroupDetailsModal({
    isOpen = false,
    data,
    handleClose = () => {},
    handleUpdateGroupInfo = () => {},
    initialGroupName,
    initialGroupPhoneNumber
}) {
    const [groupName, setGroupName] = useState("");
    const [groupPhoneNumber, setGroupPhoneNumber] = useState("");

    // INIT STATE
    useEffect(() => {
        if (isOpen) {
            setGroupName(initialGroupName);
            setGroupPhoneNumber(initialGroupPhoneNumber);
        }
    }, [isOpen]);
  
    return (
        <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Edit Group Details</DialogTitle>
          <DialogContent>
            <FormControl
                style={{margin: "20px 0"}}
                fullWidth
                variant="outlined"
                required>
                <FormLabel>Group Name</FormLabel>
                <TextField
                    variant="outlined"
                    id="groupName"
                    value={groupName}
                    onChange={e => setGroupName(e.target.value)}
                    fullWidth
                    multiline
                />
            </FormControl>
            <FormControl
                style={{margin: "20px 0"}}
                fullWidth
                variant="outlined"
                required>
                <FormLabel>Group Phone Number</FormLabel>
                <TextField
                    variant="outlined"
                    id="groupPhoneNumber"
                    value={groupPhoneNumber}
                    onChange={e => setGroupPhoneNumber(e.target.value)}
                    fullWidth
                    multiline
                />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={e => handleUpdateGroupInfo(groupName, groupPhoneNumber)} color="primary">
              Save Group
            </Button>
          </DialogActions>
        </Dialog>
    );
  };