/* eslint-disable */
import React from 'react';
import MaterialTable from "material-table";

const SubscriberCountTable = ({tableData = {}}) => {

    const parsedData = tableData.data.reduce((acc, {date, subscriberCount, groupName, totalStateCount, state}, index, array) => {
        const sumArray = (a1, a2) => [a1[0] + a2[0], a1[1] + a2[1], a1[2] + a2[2]];
        if (!acc.headers.some(v => v.title === date)) acc.headers.push({
            title: date, field: date,
            render: rowData => {
                return rowData['state-group'] === 'Total' ?
                    <div><span style={{fontWeight: 'bold'}}>{rowData[date]}</span><br/></div> :
                    <div><span style={{color: 'grey'}}>{rowData[date]}</span><br/></div>
            }
        })

        if (!acc.data.some(v => v.id === state))
            acc.data.push({
                id: state,
                'state-group': state,
            })
        const parentIndex = acc.data.findIndex(v => v.id === state)
        const {stateAndDayCount} = tableData.dayStateCount.find(v => v.startDate === date && v.state === state)
        if (!acc.data[parentIndex][date]) acc.data[parentIndex][date] = [subscriberCount];
        else acc.data[parentIndex][date] = acc.data[parentIndex][date] = [stateAndDayCount];
        if (!acc.data[parentIndex].total) acc.data[parentIndex].total = tableData.stateCount.find(s => Object.keys(s).some(k => k === state))[state];

        if (!acc.data.some(v => v.id === groupName))
            acc.data.push({
                id: groupName,
                'state-group': groupName,
                parentId: state,
            })
        const groupIndex = acc.data.findIndex(v => v.id === groupName)
        acc.data[groupIndex][date] = [subscriberCount]
        if (!acc.data[groupIndex].total) acc.data[groupIndex].total = [tableData.groupTotalCounter.find(t => t.groupName === groupName).groupTotalCounter];

        const totalRowId = 'zzzz'
        if (!acc.data.some(v => v.id === totalRowId))
            acc.data.push({
                id: totalRowId,
                'state-group': 'Total',
            })
        const totalIndex = acc.data.findIndex(v => v.id === totalRowId)

        if (!acc.data[totalIndex][date]) {
            const {totalStateDayCount} = tableData.totalStateCountMap.find(v => v.date === date)
            acc.data[totalIndex][date] = [totalStateDayCount]
        }

        if (index === array.length - 1) {
            acc.headers.push({
                title: "Total",
                render: rowData => <div><span style={{fontWeight: 'bold'}}>{rowData.total}</span></div>
            })
            acc.data[totalIndex].total = tableData.totalStateCountMap.reduce((a, b) => a + b.totalStateDayCount, 0)
        }

        return acc
    }, {
        headers: [
            {
                title: 'State/Group Name', field: 'state-group',
                render: rowData => {
                    return rowData.parentId == null ?
                        <div style={{'font-weight': 'bold'}}>{rowData['state-group']}</div> :
                        <div style={{'font-weight': 'normal'}}>{rowData['state-group']}</div>
                }
            },
        ], data: []
    })


    let {headers, data} = parsedData;
    const customSort = (a, b) => {
        if (a.id < b.id) {
            return -1;
        }
        if (a.id > b.id) {
            return 1;
        }
        return 0;
    }
    data = data.sort(customSort)
    return (

        <MaterialTable
            title=""
            data={data}
            columns={headers}
            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
            options={
                {
                    draggable: false,
                    paging: false,
                    sorting: false,
                    maxBodyHeight: '40vh',
                    headerStyle: {
                        background: "linear-gradient(to right, #1b4297, #8490d9)",
                        font: "Whitney, sans-serif",
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#ffffff",
                        whiteSpace: "nowrap",
                        // minWidth: "160px",
                    },
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.id === 'zzzz') ? '#C8C8C8' : '#FFF',
                    }),
                }
            }
        />
    )
};

export default SubscriberCountTable;